import { Button, CircularProgress, Input, MenuItem, Select, TextField, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles';

import React, { useState } from 'react'
import Containter from '../../../Components/Container/Container';
import MatchRotura from './MatchRotura';
import toast, { Toaster } from 'react-hot-toast';

const LLavePlanillaRotura = (props) => {
    const { datos,
        handleWin,
        cancelMatch,
        finalizar,
        intentoRotura,
        siguienteRonda,
        anteriorRonda


    } = props
    const [competidor, setCompetidor] = useState(datos.llave[0])
    const [showMatch, setShowMatch] = useState(false);


    console.log("ROTURA", datos)
    let llave = datos.llave;
    let categoria_id = datos.llave[0].categoria_id;
    let liga_id = datos.llave[0].liga_id;
    let dia = datos.llave[0].dia;
    let ronda = datos.rondas ;
    let estado = datos.estado;
    let altoPosicion = 40;
   

    const estadoEstado = () => {
        let x = false
        if (estado == 3) { x = true }
        return x
    }

    const handleChangePulgada = (comp, puntos) => {

        intentoRotura(comp, puntos,ronda)
        setShowMatch(false)
    }

    const cancel = () => {

        setShowMatch(false)
    }

    const selectCompetidor = (x) => {

        setCompetidor(x)
        setShowMatch(true)

    }


    const notify = (x, nombre) => {
        if (x == 1) {
            toast.success(nombre + '.', { style: { background: 'lightgreen', color: "Black" } });
        } else {
            toast.error(nombre + '.', { style: { background: 'red', color: "white" } });
        }
    }



    return (
        <div style={{ display: "flex", flexDirection: "column", width: "100%", borderStyle: "solid", height: 700 }}   >
            <Toaster position="top-center" />
            {showMatch ? <MatchRotura listo={(x) => console.log("dd")} cancelButton={() => cancel()} siguiente={(x, p) => handleChangePulgada(x, p)} ronda={ronda} datos={competidor} />
                :
                <>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", width: "100%", borderStyle: "solid", height: 100}}   >

                        <Button disabled={estado == 3 || ronda+1 < 3 ? true : false}
                            onClick={() => finalizar({ categoria_id: llave[0].categoria_id, liga_id: llave[0].liga_id, dia: llave[0].dia })}
                            style={{ background: "red", marginRight: 30, marginLeft: 20 }}>FINALIZAR</Button>


                        <Button disabled={estado == 3  || ronda < 1   ? true   : false}
                            onClick={() => ronda ==1 || ronda  == 2 ? anteriorRonda(categoria_id, liga_id, dia,  ronda ) : console.log(ronda+1)}
                            style={{ background: "green", marginRight: 10, marginLeft: 30 }}>
                            {"<<<"}
                        </Button>

                        <Typography variant='h3'>
                            {ronda+1 < 3 ? "RONDA " + (ronda+1) : "RONDA  3"}
                        </Typography>

                        <Button disabled={estado == 3 || ronda > 1? true : false}
                            onClick={() => ronda+1 <= 3 ? siguienteRonda(categoria_id, liga_id, dia, ronda) : console.log(ronda )}
                            style={{ background: "green", marginRight: 10, marginLeft: 20 }}>
                            {">>>"}
                        </Button>
                    </div>
                  
                    <div style={{ width: "100%", display:"flex",justifyContent:"center" , marginTop:"40px"  }}  >
                        <div style={{ width: "850px" , borderStyle:"solid",borderWidth:0.5 }}  >
                            <div style={{ display: "flex", flexDirection: "row", width: "850px", height: 50 , borderStyle:"solid",borderWidth:0.8, justifyItems:"center" }}   >
                                <Typography style={{ width: 50, textAlign: "center",height:50, alignContent:"center" }}>Nro</Typography>
                                <Typography style={{ width: 300, textAlign: "center",height:50, alignContent:"center" }}>Nombre</Typography>
                                <Typography style={{ width: 100, textAlign: "center" ,height:50, alignContent:"center"}}>Categoria</Typography>
                                <Typography style={{ width: 100, textAlign: "center",height:50, alignContent:"center" }}>EDAD</Typography>
                                <Typography style={{ width: 100, textAlign: "center",height:50, alignContent:"center", backgroundColor:ronda+1==1?"green":"transparent" }}>Ronda 1</Typography>
                                <Typography style={{ width: 100, textAlign: "center",height:50, alignContent:"center" , backgroundColor:ronda+1==2?"green":"transparent" }}>Ronda 2</Typography>
                                <Typography style={{ width: 100, textAlign: "center" ,height:50, alignContent:"center", backgroundColor:ronda+1==3?"green":"transparent" }}>Puesto</Typography>


                            </div>
                            {llave.map((x, i) => {
                                return (
                                    <div style={{ display: "flex", flexDirection: "row", width: "850px", height: 50, borderBottomStyle: "solid", borderBottomColor: "GrayText", borderBottomWidth: 1 }}>
                                        <Typography style={{ width: 50, textAlign: "center", alignContent: "center" }}>{i + 1}</Typography>
                                        <Typography
                                            
                                            onClick={() => estado !== 3 && x.intento2!==0 ? selectCompetidor(x) : console.log("nada")}
                                            style={{ cursor: estado !== 3 ? "pointer" : "", width: 300, textAlign: "center", alignContent: "center" }}>
                                            {x.nombre}
                                        </Typography>
                                      
                                        <Typography style={{ width: 100, textAlign: "center", alignContent: "center", fontSize: 10 }}>{x.categoria}</Typography>

                                        <Typography style={{ width: 100, textAlign: "center", alignContent: "center" }}> {x.edad}</Typography>

                                        <div style={{ borderLeftStyle: "solid", borderLeftWidth: 1, borderLeftColor: "grey", width: 100, alignContent: "center", height: "100%", background: ronda+1 == 1 ? "green" : "transparent", color: ronda+1 == 1 ? "black" : "white" }}>
                                            <Typography style={{ width: 100, textAlign: "center", fontSize: ronda+1 == 1 ? 25 : 15 }}>{x.intento1 == null ? "--" : x.intento1 == 0 ? "XX" : x.intento1}</Typography>
                                        </div>

                                        <div style={{ borderLeftStyle: "solid", borderLeftWidth: 1, borderLeftColor: "grey", width: 100, alignContent: "center", background: ronda+1 == 2 ? "green" : "transparent", color: ronda+1 == 2 ? "black" : "white" }}>
                                            <Typography style={{ width: 100, textAlign: "center", fontSize: ronda+1 == 2 ? 25 : 15 }}>{x.intento2 == null ? "--" : x.intento2 == 0 ? "XX" : x.intento2}</Typography>
                                        </div>

                                        <div style={{ width: 100, alignContent: "center" }}>
                                            <Typography style={{ width: 100, textAlign: "center", alignContent: "center", color: "white", fontSize: x.resultado > 0 ? 25 : 10 }}>{x.resultado == null ? "--" : x.resultado + "º"}</Typography>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </>
            }



        </div>

    )
}
export default LLavePlanillaRotura
