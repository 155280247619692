import { Button, IconButton, MenuItem, Select, TextField, Tooltip, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles';
import { DataGrid } from '@mui/x-data-grid'
import React, { useEffect, useState } from 'react'
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import GroupRoundedIcon from '@mui/icons-material/GroupRounded';
import PDFCertificadosPremios from '../PDFplanillas/PDFCertificadosPremios';
import PDFCertificadosAsistencia from '../PDFplanillas/PDFCertificadosAsistencia';
const Styles = makeStyles({
    tableContainer: {
        display: 'flex',
        flexDirection: "row",
        justifyContent: 'center',
        alignItems: "center",
        // minWidth: "90%",
        borderStyle: "solid",

        borderRadius: 10,
        height: 100,
    },
    noDataContainer: {
        border: 'solid 1px white',
        padding: '0.2em 1em 0.2em 1em',
        borderRadius: '5px',
        minWidth: '90%',
    },
    filtros: {
        margin: 10
    },
    botonSi: {
        textAlign: 'center',
        background: "green",
        height: 50,
        margin: 10
    },
    botonNormal: {
        textAlign: 'center',
        background: "red",
        height: 50,
        margin: 10
    },
    botonNo: {
        textAlign: 'center',
        background: "red",
        height: 50,
        margin: 10
    },
    subtitulo: {
        color: "orange",
        textAlign: "center",
        width: "100%"
    },

    nombre: {
        cursor: "pointer",
    },
    text: {

        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        marginLeft: 20,
        marginRight: 20,
        height: 50,


    },
})

const AreaCentralTable = (props) => {
    const { data, loading, areas, changeArea, habilitaArea, notificacion  } = props;
    const clases = Styles()
    const [categorias, setCategorias] = useState([])
    const [certPremios, setCertPremios] = useState({open:false})
    const [certAsistencia, setCertAsistencia] = useState({open:false})
    const [params, setParams] = useState([{
        id: 1,
        disciplina: "",
        sexo: "",
        categoria_desde: "",
        categoria_hasta: "",
        edad_desde: 0,
        edade_hasta: 99,
        altura_desde: 50,
        altura_hasta: 200,
        peso_desde: 1,
        peso_hasta: 180,

    }])
    const [tablaPosiciones, setTablaPosiciones] = useState([])

    const PDFCertificadoPremios = async (data,tipo) => {
        try {  
           
            let res = await fetch(`${process.env.REACT_APP_BACKEND_LIGA}/central/getDataPremiosCentral/${data.id}&${data.liga_id}&${data.dia}`, {
                method: "get",
                headers: { 'Content-type': 'application/json' },    
            })
            let response = await res.json()
            if (response.code == 200) {   
                setCertPremios({open:true,alumno:response.datos,tipo_compe:tipo})
                console.log("200", response)
            } else {
                notificacion(0,response.mensaje)
                console.log("no200", response)
            }


        } catch (error) {
            console.log(error)
        }

    }
     
    const PDFCertificadoAsistencia = async (data ) => {
        try {  
           
            let res = await fetch(`${process.env.REACT_APP_BACKEND_LIGA}/central/getDataCertAsistencia/${data.id}&${data.liga_id}&${data.dia}`, {
                method: "get",
                headers: { 'Content-type': 'application/json' },    
            })
            let response = await res.json()
            if (response.code == 200) {   
                setCertAsistencia({open:true,alumno:response.datos })
                console.log("200", response)
            
            } else {
             
                console.log("no200", response)
                notificacion(0,response.mensaje)
            }


        } catch (error) {
            console.log(error)
        }

    }  
     
    
    const columns = [

        { field: 'id', headerName: 'Nro', minWidth: 50, renderCell: row => <div style={{ textAlign: "center", width: "100%" }}>{row.row.id}</div> },
        { field: 'disciplina', headerName: 'Disciplina', minWidth: 150 },
        { field: 'nombre', headerName: 'Nombre y Apellidos', minWidth: 500, },
        { field: 'cantidad', headerName: 'Cant.', minWidth: 100, },
        {
            field: 'area', headerName: 'Area', minWidth: 200, renderCell: row =>
                <div style={{ textAlign: "center", width: "100%" }}>
                    <Select
                        style={row.row.estado == 3 ? { width: "40%" } : { width: "80%" }}            
                        onChange={(e) => changeArea(e.target.value, row.row)}
                        defaultValue={row.row.area > 0 ? row.row.area : 0} >
                        <MenuItem id={0} value={0}>Sin Asignar</MenuItem>
                        {areas.map((x) => {
                            return (<MenuItem id={x.area} value={x.area}>Area {x.area}</MenuItem>)
                        })}
                    </Select>
                    {row.row.estado == 3 && <Button onClick={() => habilitaArea(row.row)} style={{ fontSize: 10, backgroundColor: "red", width: "30%", marginLeft: "10%" }}>Habilitar</Button>
                    }
                </div>
        },
        {
            field: 'tipo_compe', headerName: 'Tipo', minWidth: 100, renderCell: row =>
                <div style={{ textAlign: "center", width: "100%" }}>
                    {row.row.tipo_compe ==1 && <Typography  >Eliminacion</Typography>}
                    {row.row.tipo_compe== 2 && <Typography  >Grupos</Typography>}
                    {row.row.tipo_compe == 3 && <Typography  >Planilla</Typography>}      
                </div>

        },


        {
            field: 'estado', headerName: 'Estado', minWidth: 100, renderCell: row =>
                <div style={{ textAlign: "center", width: "100%" }}>

                    {row.row.estado == 0 && <Typography style={{ color: "orangered" }}>Sin Area</Typography>}

                    {row.row.estado == 1 && <Typography style={{ color: "goldenrod" }}> por Iniciar   </Typography>}

                    {row.row.estado == 2 && <Typography style={{ color: "white" }}> en Curso...  </Typography>}

                    {row.row.estado == 3 && row.row.area > 0 && <Typography style={{ color: "greenyellow" }}> Finalizado </Typography>}
                </div>

        },
        {
            field: 'certificados', headerName: 'Certif.', minWidth: 100, renderCell: row => {
                return (<IconButton
                   // disabled={determinaInicioFecha().activar}
                    style={{ color: "white", width: "100%" }}
                    onClick={() =>PDFCertificadoPremios(row.row,row.row.tipo_compe)}>
                    <Tooltip title="Premios">
                        <WorkspacePremiumIcon fontSize="medium" />
                    </Tooltip>
                </IconButton>
                )
            }

        },
        {
            field: 'partic.', headerName: 'part.', minWidth: 100, renderCell: row => {
                return (<IconButton
                   // disabled={determinaInicioFecha().activar}
                    style={{ color: "white", width: "100%" }}
                    onClick={() => PDFCertificadoAsistencia(row.row)}>
                    <Tooltip title="Participacion">
                        <GroupRoundedIcon fontSize="medium" />
                    </Tooltip>
                </IconButton>
                )
            }

        },
    
    ]

    return (
        <div style={{ width: "100%" }}>
      
         {certPremios.open && <PDFCertificadosPremios alumnos={certPremios.alumno}   tipo_compe={certPremios.tipo_compe}  listo={(x)=>setCertPremios({...certPremios,open:x})} />}
      
         {certAsistencia.open && <PDFCertificadosAsistencia alumnos={certAsistencia.alumno}     listo={(x)=>setCertAsistencia({...certAsistencia,open:x})} />}
      
            {
                data ?
                    <DataGrid

                        autoHeight
                        disableColumnMenu
                        disableColumnFilter
                        disableSelectionOnClick
                        rows={data}
                        pageSize={90}
                        loading={loading}
                        columns={columns}
                        paginationMode='server'
                        isRowSelectable={false}
                        hideFooter
                        hideFooterPagination
                        rowCount={data.length}

                    />
                    :
                    <div className={clases.noDataContainer}>
                        <h2 className={clases.noDataText}>{"mensaje"}</h2>
                    </div>
            }
        </div>


    )
}

export default AreaCentralTable