
import jsPDF from 'jspdf';
import React from 'react';
import logo from "./../../../Assets/Imagenes/Logo_SUPERCOPA.png"




const PDFPlanillaSalto = (props) => {

  const { alumnos, disciplina, categoria, listo } = props;
 
  const sexo=(valor)=>{
    let sexo="Mixto"

    switch (valor) {
      case 1:
        sexo="Femenino"
        break;
        case 2:
          sexo="Masculino"
          break;
          case2:
            sexo="Mixto"
            break;
    
      default:
        break;
    }
    return sexo
  }

  const NC = (num) => {
    let categoria = "n/c"
    switch (num) {

      case 1:
        categoria = "Blanco"
        break;
      case 2:
        categoria = "Punta Amarilla"
        break;
      case 3:
        categoria = "Amarillo"
        break;
      case 4:
        categoria = "Punta Verde"
        break;
      case 5:
        categoria = "Verde"
        break;
      case 6:
        categoria = "punta Azul"
        break;
      case 7:
        categoria = "Azul"
        break;
      case 8:
        categoria = "Punta Roja"
        break;
      case 9:
        categoria = "Rojo"
        break;
      case 10:
        categoria = "Punta Negra"
        break;
      case 11:
        categoria = "1º Dan"
        break;
      case 12:
        categoria = "2º Dan"
        break;
      case 13:
        categoria = "3º Dan"
        break;
      case 14:
        categoria = "4º Dan"
        break;
      case 15:
        categoria = "5º Dan"
        break;
      case 16:
        categoria = "6º Dan"
        break;
      case 17:
        categoria = "7º Dan"
        break;
      case 18:
        categoria = "8º Dan"
        break; case 19:
        categoria = "9º Dan"
        break;


      default:
        break;
    }

    return categoria;
  }


  let round = 0 // cero es la final
  let cant = alumnos.length;
  let vacios = 0

  if (cant <= 2) { round = 0 }
  else if (cant == 3 || cant == 4) { round = 1; vacios = 4 - cant }
  else if (cant >= 5 && cant <= 8) { round = 2; vacios = 8 - cant }
  else if (cant >= 9 && cant <= 16) { round = 3; vacios = 16 - cant }
  else if (cant >= 17 && cant <= 32) { round = 4; vacios = 32 - cant }

  let ronda1 = [];
  let ronda2 = [];
  alumnos.map((x, i) => {
    let pos = i + 1;
    if (pos <= vacios) {
      ronda2.push(x)
    } else {
      ronda1.push(x)
    }
  })
 




  let x = 10;
  let y = 10;


  const doc = new jsPDF({
    orientation: "l",
    unit: "mm",
    format: "a4"
  });

  doc.cell(x, y, 280, 25, "  ") //cuadrado encabezado
  doc.setFontSize(17)
  doc.text( ` ${disciplina.toUpperCase()} (${sexo(categoria.sexo)})`, 120, 17)     // x==>>
  doc.setFontSize(10)
  doc.text(`2024`, 255, 15)
  doc.addImage(logo, "PNG", 250, 15, 20, 20);
  doc.text(`Categoria:   ${NC(categoria.categoria_desde)} a ${NC(categoria.categoria_hasta)}.`, 15, 15)
  doc.text(`Categoria Nro: ${categoria.id}`, 125, 25)
  doc.text(`Edad:    ${categoria.edad_desde} a ${categoria.edad_hasta} años.`, 15, 21)
  doc.text(`Altura:  de ${categoria.altura_desde} a ${categoria.altura_hasta} cm.`, 15, 27)
  doc.text(`Peso:  de ${categoria.peso_desde} a ${categoria.peso_hasta} Kg.`, 15, 33)

  y = 45
  x = 10;
  doc.setLineWidth(0.2);

    let competidores = cant - vacios;
     let compet4= cant-vacios;
     
    for (let index = 0; index < compet4; index++) {
      doc.text(x, y ,ronda1[index].nombre+" "+ronda1[index].apellido)
      doc.line(x, y + 1, x + 60, y + 1);

      if (index % 2 == 1){
             doc.line(x+60, y+1, x+60, (y+1)-x );
            }
      y = y + 10
    }
   
    x = 70
    y = 50
    let compet3= (compet4 /2) + vacios;
    let cont=0;
    for (let index = 0; index < compet3; index++) {
    
      if (index  >= (compet4/2) ){
      
        doc.text(x, y ,ronda2[cont].nombre+" "+ronda2[cont].apellido) ;
      cont=cont+1;
      }
     
      doc.line(x, y + 1, x + 60, y + 1);
   
      if (index % 2 == 1  ){
        doc.line(x+60, y+1, x+60, (y+1)-(x -50) );
       }
    
       y = y + 20

     vacios=0
    }

    x = 130
    y =60
    let compet2= (compet3 /2) + vacios;
    for (let index = 0; index < compet2; index++) {
     
      doc.line(x, y + 1, x + 50, y + 1);
      if (index % 2 == 1  ){
        doc.line(x+50, y+1, x+50, (y+1)-(x -90) );
       }
      y = y + 40
      vacios=0;
    }
    x = 180
    y =80
    let compet1= (compet2 /2) + vacios;
    for (let index = 0; index < compet1; index++) {

      doc.line(x, y + 1, x + 50, y + 1);
      if (index % 2 == 1  ){
        doc.line(x+50, y+1, x+50, (y+1)-(x -100) );
       }
      y = y + 80
      vacios=0;
    }
    x = 230
    y =120

    let compet0= (compet1 /2) + vacios;
    for (let index = 0; index < compet0; index++) {

      doc.line(x, y + 1, x + 50, y + 1);
      
      y = y + 160
    }



  x = 60;

  let nombrePDF= `${disciplina.toUpperCase()}_${categoria.edad_desde}a${categoria.edad_hasta}_${NC(categoria.categoria_desde)}a${NC(categoria.categoria_hasta)}.`

  doc.output('save', `${nombrePDF}.pdf`);
 

  listo()
  return (
    <div>
    </div>
  );
}

export default PDFPlanillaSalto;