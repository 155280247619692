
import { Button, Input, MenuItem, Select, TextField, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles';
import { DataGrid } from '@mui/x-data-grid'
import React, { useState } from 'react'
import Containter from '../../../Components/Container/Container';


const HeaderArea = (props) => {
    const { area, categorias,selectCate ,disabled, categorySelect} = props

const [seleccion,setSeleccion]=useState([]);

const HandleselectCate =(e)=>{

let categoria_seleccionda=categorias.filter((x)=>x.id==e)

 setSeleccion(categoria_seleccionda[0])
 selectCate(e);
}
 
    return (
        <div style={{ borderStyle: "solid", borderWidth: 1, borderRadius: 5, width: "100%", textAlign: "center", padding: 30 }}>
            <Typography variant="h4"> AREA Nro {area}</Typography>
            <div style={{ width: "100%" }}>
                <Select
                style={{fontSize:25}}
                    onChange={(e) => { HandleselectCate(e.target.value) }}
                   value={categorySelect}
                    disabled={disabled}
                >
                    <MenuItem disabled id={0} value={0}>Seleccione LLAve</MenuItem>
                    {categorias?.map((x) => {
                      return( <MenuItem style={{color:x.estado==3?"green":"white"}} id={x.id} value={x.id}>{"Nro "+x.id+" "+ x.nombre }{  x.estado==3?" (FINALIZADO)":""}     </MenuItem> )
                    })}                 
                </Select>
            </div>
        </div>
    )
}
export default HeaderArea