import { Button, IconButton, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles';
import React, { useState, useEffect } from 'react'
import CoPresentIcon from '@mui/icons-material/CoPresent';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import LLaveActiva from './LLaveActiva';
import LLaveGruposGup from './LLavegruposGup';
import LLavePlanillaRotura from './LLavePlanillaRotura';
import LLavePlanillaSalto from './LLavePlanillaSalto';
import { useLocation, useNavigate } from "react-router-dom";
import Containter from '../../../Components/Container/Container';
import toast, { Toaster } from 'react-hot-toast';
import DataCategoty from './Encabezado/DataCategory';

const Styles = makeStyles({
    tableContainer: {
        display: 'flex',
        justifyContent: 'center',
        minWidth: "90%",
        borderStyle: "solid",
        borderWidth: 2,
        borderRadius: 10,
        height: 100,
    },
    recuadro: {
        width: "60%",
        display: "flex",
        flexDirection: "row",
        borderWidth: 0.5,
        borderStyle: "solid",
        marginLeft: "20%",
        "@media (max-width: 910px)": {
            marginLeft: "10px",
            width: "500px"

        }
    }


})



const LLavesGeneral = (props) => {
    const clases = Styles();
    const navigate = useNavigate()
    const location = useLocation();

    var tip = 0
    var llaveState = []
    var categoria = []
    var dia = 0;
    var liga = 0;
    if (location.state) {
        tip = location.state.tipo
        llaveState = location.state.llave
        categoria = location.state.categoria
        dia = location.state.categoria.dia;
        liga = location.state.categoria.liga_id
    }


    const [llaveAsistencia, setLLaveAsistencia] = useState([])
    const [llaveEncuentro, setLLaveEncuentro] = useState([])
    const [competidores, setCompetidores] = useState([])
 
    const [llave, setLLave] = useState(llaveState);
    const [categorias, setCategorias] = useState([]);
    const [categoriaSelect, setCategoriaSelect] = useState(0);
    const [estadoLLave, setEstadoLLave] = useState(0);
    const [activeMatch, setActiveMatch] = useState(false)
    const [tipo, setTipo] = useState(tip);
    const [disciplina, setDisciplina] = useState(categoria.disciplina_id);


    useEffect(() => {

    }, [])

    const notify = (x, nombre) => {
        if (x == 1) {
            toast.success(nombre + '.', { style: { background: 'lightgreen', color: "Black" } });
        } else {
            toast.error(nombre + '.', { style: { background: 'red', color: "white" } });
        }
    }


    const getLLaveArea = async (categoria_id) => {
        try {

            setCategoriaSelect(0)

            let res = await fetch(`${process.env.REACT_APP_BACKEND_LIGA}/getLLaveArea/${categoria_id}&${dia} `,
                {
                    method: "GET",
                    headers: { 'Content-type': 'application/json' },
                    // body: JSON.stringify(params)
                })
            let response = await res.json()
            if (response.code == 200) {

                setLLave(response.data.llave)
                setTipo(response.data.tipo)
                setDisciplina(response.data.disciplina)
                setCategoriaSelect(categoria_id)

            } else if (response.code == 400) {
                setLLave([])
            }
        } catch (error) {
            console.log(error)
        }
    }
    //*******ELIMINACION*************** */
    const fetchHandleWin = async (d) => {
        try { //update
            let id = d.win.id ? d.win.id : 0
            let data = d.win

            let res = await fetch(`${process.env.REACT_APP_BACKEND_LIGA}/winEliminacion/${id}`, {
                method: "PUT",
                headers: { 'Content-type': 'application/json' },
                body: JSON.stringify(data)
            })

            let response = await res.json()

            if (response.code == 200) {
                setLLave(response.data)
                // getLLaveArea(d.win.categoria_id)
                console.log("200", response)
            } else {
                console.log("no200", response)
            }


        } catch (error) {
            console.log(error)
        }

    }
    const CancelMatch = async (d) => {
        try { //update
            let id = d.id
            let data = d;

            let res = await fetch(`${process.env.REACT_APP_BACKEND_LIGA}/cancelMatchElim/${id}`, {
                method: "PUT",
                headers: { 'Content-type': 'application/json' },
                body: JSON.stringify(data)
            })

            let response = await res.json()

            if (response.code == 200) {
                setLLave({ ...llave, llave: response.llave })
                console.log("200", response)
            } else {
                console.log("no200", response)
            }


        } catch (error) {
            console.log(error)
        }

    }
    const finalizaLLaveEliminacion = async (datos) => {
        try {

            let res = await fetch(`${process.env.REACT_APP_BACKEND_LIGA}/cerrarLLaveEliminacion/${datos.categoria_id}&${datos.liga_id}&${datos.dia} `,
                {
                    method: "GET",
                    headers: { 'Content-type': 'application/json' },
                    // body: JSON.stringify(params)
                })
            let response = await res.json()
            if (response.code == 200) {

                setLLave({ ...llave, estado: response.estado })

            } else if (response.code == 400) {

            }
        } catch (error) {
            console.log(error)
        }
    }
    //*******GRUPOS******************** */
    const fetchHandleWinGrupos = async (d) => {
        try { //update
            let id = d.competidor1.categoria_id
            let data = d;

            let res = await fetch(`${process.env.REACT_APP_BACKEND_LIGA}/winGrupo/${id}`, {
                method: "PUT",
                headers: { 'Content-type': 'application/json' },
                body: JSON.stringify(data)
            })

            let response = await res.json()

            if (response.code == 200) {
                setLLave(response.data)
                //   getLLaveArea(id)
                console.log("200", response.data)
            } else {
                console.log("no200", response)
            }


        } catch (error) {
            console.log(error)
        }

    }

    const finalizaLLaveGrupos = async (datos) => {
        try {

            let res = await fetch(`${process.env.REACT_APP_BACKEND_LIGA}/cerrarLLaveGrupos/${datos.categoria_id}&${datos.liga_id}&${datos.dia} `,
                {
                    method: "GET",
                    headers: { 'Content-type': 'application/json' },
                    // body: JSON.stringify(params)
                })
            let response = await res.json()
            if (response.code == 200) {

                setLLave({ ...llave, estado: response.estado })

            } else if (response.code == 400) {

            }
        } catch (error) {
            console.log(error)
        }
    }

    //************SALTO**************** */

    const fetchCambioPuestoSalto = async (c) => {

        try { //update
            let data = c;
            let res = await fetch(`${process.env.REACT_APP_BACKEND_LIGA}/cambioPuestosSalto`,
                {
                    method: "POST",
                    headers: { 'Content-type': 'application/json' },
                    body: JSON.stringify(data)
                })
            let response = await res.json()
            if (response.code == 200) {
                setLLave(response.data)
            } else {
                console.log("no200", response)
            }
        } catch (error) {
            console.log(error)
        }

    }

    const finalizaPlanillaSalto = async (datos) => {
        try {

            let res = await fetch(`${process.env.REACT_APP_BACKEND_LIGA}/cerrarLLaveSalto/${datos.categoria_id}&${datos.liga_id}&${datos.dia} `,
                {
                    method: "GET",
                    headers: { 'Content-type': 'application/json' },
                    // body: JSON.stringify(params)
                })
            let response = await res.json()
            if (response.code == 200) {

                setLLave(response.data)
            } else if (response.code == 400) {

            }
        } catch (error) {
            console.log(error)
        }
    }

    //************ROTURA **************** */

    const fetchIntentoRotura = async (comp, puntos, ronda) => {
        try { //update
            let id = comp.competidor_id
            let data = { ...comp, totalPuntos: puntos.total };
            let res = await fetch(`${process.env.REACT_APP_BACKEND_LIGA}/intentoRotura/${id}&${ronda}`, {
                method: "PUT",
                headers: { 'Content-type': 'application/json' },
                body: JSON.stringify(data)
            })

            let response = await res.json()

            if (response.code == 200) {

                setLLave({ ...llave, llave: response.data.llave })

                if (response.status == "regular") {
                    // notify(0, response.data.mensaje)
                }

                console.log("200", response)
            } else {

                console.log("no200", response)
            }


        } catch (error) {
            console.log(error)
        }


    }
    const ChangeRondaRotura = async (categoria_id, liga_id, dia, ronda) => {
        try {
            let res = await fetch(`${process.env.REACT_APP_BACKEND_LIGA}/siguienteRondaRotura/${categoria_id}&${liga_id}&${dia}&${ronda} `,
                {
                    method: "get",
                    headers: { 'Content-type': 'application/json' },
                })
            let response = await res.json()
            if (response.code == 200) {

                setLLave({ ...llave, llave: response.data.llave, rondas: response.data.rondas })
                notify(1, response.mensaje)
            } else if (response.code == 400) {
                notify(0, response.mensaje)
            }
        } catch (error) {
            console.log(error)
        }
    }
    const anteriorRondaRotura = async (categoria_id, liga_id, dia, ronda) => {
        try {
            let res = await fetch(`${process.env.REACT_APP_BACKEND_LIGA}/anteriorRondaRotura/${categoria_id}&${liga_id}&${dia}&${ronda} `,
                {
                    method: "get",
                    headers: { 'Content-type': 'application/json' },
                })
            let response = await res.json()
            if (response.code == 200) {

                setLLave({ ...llave, rondas: response.data.rondas })
                notify(1, response.mensaje)
            } else if (response.code == 400) {
                notify(0, response.mensaje)
            }
        } catch (error) {
            console.log(error)
        }
    }
    const finalizaPlanillaRotura = async (datos) => {
        try {
            let res = await fetch(`${process.env.REACT_APP_BACKEND_LIGA}/cerrarLLaveRotura/${datos.categoria_id}&${datos.liga_id}&${datos.dia} `,
                {
                    method: "GET",
                    headers: { 'Content-type': 'application/json' },
                })
            let response = await res.json()
            if (response.code == 200) {

                setLLave({ ...llave, estado: response.estado })
            } else if (response.code == 400) {

            }
        } catch (error) {
            console.log(error)
        }
    }

    return (

        <Containter styles={{ width: "100%" }} more>
            <Toaster position="top-center" />

         <DataCategoty   data={location.state.categoria} />
         
            <div style={{ display: "flex", flexDirection: "row", width: "100%", height: "50px", marginTop: "20px", marginBottom: "10px", alignContent: "center" }}>




                <Button
                    onClick={() => navigate("/areas/AsistenciaCompetidor", { state: { categoria:categoria } })}
                    style={{ background: "red", width: "150px", height: "50px" }}
                > VOLVER  </Button>
                
            </div>



            {tipo == 1 &&
                <LLaveActiva
                    datos={llave}
                    // estado={estadoLLave}
                    // handleAsist={(a) => fetchAsistencia(a)}
                    activeMatch={(x) => setActiveMatch(x)}
                    handleWin={(x) => fetchHandleWin(x)}
                    cancelMatch={(x) => CancelMatch(x)}
                    finalizar={(x) => finalizaLLaveEliminacion(x)}
                    listoXausente={(x) => fetchHandleWin(x)}
                />
            }


            {tipo == 2 &&
                <LLaveGruposGup
                    datos={llave}                  
                    handleWin={(x) => fetchHandleWinGrupos(x)}
                    finalizar={(x) => finalizaLLaveGrupos(x)}
                />
            }

            {tipo == 3 && disciplina == 3 &&
                <LLavePlanillaRotura
                    datos={llave}
                    siguienteRonda={(c, l, d, r) => ChangeRondaRotura(c, l, d, r)}
                    anteriorRonda={(c, l, d, r) => anteriorRondaRotura(c, l, d, r)}
                    finalizar={(x) => finalizaPlanillaRotura(x)}
                    intentoRotura={(x, p, r) => fetchIntentoRotura(x, p, r)}
                />
            }

            {tipo == 3 && disciplina == 4 &&


                <LLavePlanillaSalto
                    datos={llave}
                    HandlePuesto={(x) => fetchCambioPuestoSalto(x)}
                    finalizar={(x) => finalizaPlanillaSalto(x)}
                />



            }



        </Containter>

    )
}
export default LLavesGeneral