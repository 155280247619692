
import jsPDF from 'jspdf'
import React, { useState } from 'react';
import logo from "./../../../Assets/Imagenes/Logo_SUPERCOPA.png"
import QRCode from 'qrcode'
 


const PDFCredenciales = (props) => {


  const { alumnos, dni, categoria, listo } = props;
  let alum = [];
  if (dni > 0) {
    let al = alumnos.filter((x) => x.dni == dni)
  alum =al[0]
  } else {
    alum = alumnos
  }

  const sexo = (valor) => {
    let sexo = "Mixto"

    switch (valor) {
      case 1:
        sexo = "Femenino"
        break;
      case 2:
        sexo = "Masculino"
        break;
        case2:
        sexo = "Mixto"
        break;

      default:
        break;
    }
    return sexo
  }

  const NC = (num) => {
    let categoria = "n/c"
    switch (num) {

      case 1:
        categoria = "Blanco"
        break;
      case 2:
        categoria = "Punta Amarilla"
        break;
      case 3:
        categoria = "Amarillo"
        break;
      case 4:
        categoria = "Punta Verde"
        break;
      case 5:
        categoria = "Verde"
        break;
      case 6:
        categoria = "punta Azul"
        break;
      case 7:
        categoria = "Azul"
        break;
      case 8:
        categoria = "Punta Roja"
        break;
      case 9:
        categoria = "Rojo"
        break;
      case 10:
        categoria = "Punta Negra"
        break;
      case 11:
        categoria = "1º Dan"
        break;
      case 12:
        categoria = "2º Dan"
        break;
      case 13:
        categoria = "3º Dan"
        break;
      case 14:
        categoria = "4º Dan"
        break;
      case 15:
        categoria = "5º Dan"
        break;
      case 16:
        categoria = "6º Dan"
        break;
      case 17:
        categoria = "7º Dan"
        break;
      case 18:
        categoria = "8º Dan"
        break; case 19:
        categoria = "9º Dan"
        break;


      default:
        break;
    }

    return categoria;
  }

  jsPDF.API.texto = function (texto, ancho, alto, fill, colorFill) {
    this.setFillColor(0)

    let TN = texto.length;
    if (TN <= 17) { this.setFontSize(18) }
    if (TN >= 18 & TN <= 21) { this.setFontSize(15) }
    if (TN >= 22) { this.setFontSize(10) }



    this.setFont('Helvetica', 'bold')
    if (fill == "FD") {
      this.rect(ancho, alto, 75, 15, 'FD')
    } else {
      this.rect(ancho, alto, 75, 15)
    }

    this.setTextColor(255)
    this.text(texto, ancho + 37.5, alto + (10), { align: "center" })

  }

  jsPDF.API.texto2 = function (texto, ancho, alto, colorFill, colorText, multi) {
    let color = 0;
    let colorT = 0;
    let an = 1;
    if (colorFill) { color = colorFill }
    if (colorText) { colorT = colorText }
    if (multi) { an = multi }
    this.setFillColor(color)
    this.setFontSize(15)
    this.setFont('Helvetica', 'bold')

    this.rect(ancho, alto, 25 * an, 10, 'FD')

    this.setTextColor(colorT)
    this.text(texto, (ancho) + (12.5 * an), alto + (7), { align: "center" })

  }

  jsPDF.API.texto3 = function (texto1, texto2, ancho, alto, fill) {
    this.setFillColor(150)
    this.setFont('Helvetica', 'bold')

    this.rect(ancho, alto, 75, 15, 'FD')
    this.setTextColor(0)
    this.setFontSize(12)
    this.text("Instructor: " + texto1, ancho + 37.5, alto + (5), { align: "center" })
    this.setFontSize(15)
    this.text(texto2, ancho + 37.5, alto + (13), { align: "center" })

  }

  let doc = new jsPDF({
    orientation: "portrait",
    unit: "mm",
    format: "a4",
    putOnlyUsedFonts: true,
    precision: 2,
  });



  let liga = 1;
  let ancho = 30;
  let alto = 15;
  let nombreLiga = "S/N"

  let cont = 0;

  let a = alum.length > 0 ? alum : [alum]
let page=0
  for (let index = 0; index < a.length; index++) {
  
      if (a[index].edad <= 11 && a[index].categoria_id < 11) { nombreLiga = "LIGA INFANTIL"; }
      if (a[index].edad >= 12 && a[index].categoria_id < 11) { nombreLiga = "LIGA MAYOR"; }
      if (a[index].edad > 8 && a[index].categoria_id >= 11) { nombreLiga = "LIGA PROFESIONAL"; }

      let nombreCompleto = `${a[index].nombre} ${a[index].apellido}`
      console.log(page,"nombre",nombreCompleto)
      doc.texto(`${nombreCompleto}`, ancho, alto, "FD")
      doc.setFontSize(35);
      doc.texto("SUPER COPA TKD", ancho + 75, alto, "FD")
      doc.rect(ancho, alto, 75, 105);
      doc.rect(ancho + 75, alto, 75, 105);
      doc.texto2("Edad", ancho, alto + 85, 150)
      doc.texto2("Altura", ancho + 25, alto + 85, 150)
      doc.texto2("Peso", ancho + 50, alto + 85, 150)
      doc.texto2(`${a[index].edad}`, ancho, alto + 95, 150)
      doc.texto2(`${a[index].altura} m.`, ancho + 25, alto + 95, 150)
      doc.texto2(`${a[index].peso} Kg.`, ancho + 50, alto + 95, 150)
      doc.texto3(`${a[index].instructor}`, `${a[index].categoria}`, ancho, alto + 70)
      doc.texto2(`${nombreLiga}`, ancho, alto + 60, 0, 255, 3)
      doc.addImage(logo, "PNG", ancho + 20, alto + 17, 40, 40);
      doc.setTextColor(0)
      doc.text(`${a[index].dni}`, ancho + 112.5, alto + 25, { align: "center" })
      doc.setFontSize(35);
      doc.text("2024", ancho + 112.5, alto + 95, { align: "center" })
      let qr = ""
      QRCode.toDataURL(`http://ranking.somacontact.com/${a[index].dni}`, { width: 300 }, (err, dataUrl1) => {
        if (err) console.error("nonono", err);
        qr = dataUrl1
      })
      doc.addImage(qr, "png", ancho + 87.5, alto + 32, 50, 50);
     cont = cont + 1
      alto = 150;
     
    
      if (cont >= 2) {
        
        
         doc.addPage()
         cont = 0;
         page=page+1
         alto = 15;
    }
  
  }

  doc.output('save', dni?`${dni}_Credencial2024.pdf`:`Credencial2024.pdf`);
  // doc.save("yourDoc.pdf");

    listo()
  return (
    <div>
    </div>
  );
}

export default PDFCredenciales;