import React, { useState, useEffect, useCallback } from "react"
import Container from "../Container/Container"
import Button from "@material-ui/core/Button"
import { CircularProgress, makeStyles, Typography } from "@material-ui/core"
import { fetchResource } from "../../Functions/Functions"
import { borderRadius } from "@mui/system"
// import { useNavigate } from 'react-router-dom'

const Styles = makeStyles({
  button: {
    width: "210px",
    backgroundColor: "#e00",
  },
  buttonDisabled: {
    width: "210px",
    backgroundColor: "#291f21",
  },
  emptyMessage: {
    margin: "1em",
    fontSize: "18",
  },
  activityListContainer: {
    padding: "0.8em",
    display: "flex",
    justifyContent: "center",
    gap: "2em",
    flexWrap: "wrap",
  },
  activityContainerErr: {
    display: "flex",
    padding: "1em",
    justifyContent: "center",
    alignItems: "center",
    gap: "0.8em",
    flexDirection: "column",
    border: "solid 1px",
    borderRadius: "1em 0 1em 0",
    boxShadow: "8px 8px 5px 0px rgba(128,0,0,0.75)",
  },
  activityContainerOk: {
    display: "flex",
    padding: "1em",
    justifyContent: "center",
    alignItems: "center",
    gap: "0.8em",
    flexDirection: "column",
    border: "solid 1px",
    borderRadius: "1em 0 1em 0",
    boxShadow: "8px 8px 5px 0px rgba(0,128,0,0.75)",
  },
  activiyName: {
    fontSize: "18px",
  },
  signedMessage: {
    fontSize: "18px",
  },
})

const ActivityLayout = () => {
  const clases = Styles()

  const [activities, setActivities] = useState([])
  const [loading, setLoading] = useState(true)

  const getAvailableActivities = useCallback(async () => {
    const [data, error] = await fetchResource("/users/activity/availableList", "get", true)
    if (data) {
      setActivities(data.data)
      setLoading(false)
    } else {
      console.error(error)
      setLoading(false)
    }
  }, [])

  const handleInscription = async (activityId) => {
    setLoading(true)
    const [data, error] = await fetchResource(`/users/activity/signUser/${activityId}`, "post", true)
    if (data) {
      getAvailableActivities()
    } else {
      setLoading(false)
      console.error(error)
    }
  }

  useEffect(() => {
    getAvailableActivities()
  }, [getAvailableActivities])

  return (
    <Container title={"Proximas Actividades"} more>
      {loading ? (
        <CircularProgress />
      ) : activities.length > 0 ? (
        <AvailableActivityList activities={activities} handleIncriptionClick={handleInscription} />
      ) : (
        <Typography className={clases.emptyMessage}>No hay actividades disponibles</Typography>
      )}
    </Container>
  )
}

export default ActivityLayout

const AvailableActivityList = ({ activities, handleIncriptionClick }) => {
  const clases = Styles()

  return (
    <div className={clases.activityListContainer}>
      {activities.map((activity) => {
        return (
          <div className={activity.inscripto ? clases.activityContainerOk : clases.activityContainerErr}>
            <div className={clases.activiyName}>{activity.nombre}</div>
            <div className={clases.activiyName}>{activity.fecha}</div>
            <div className={clases.activiyName}>{activity.lugar}</div>
            {activity.inscripto ? (
              <div className={clases.signedMessage}>Ya se encuentra inscripto</div>
            ) : (
              <Button className={clases.button} onClick={() => handleIncriptionClick(activity.id)}>
                Inscribirme
              </Button>
            )}
          </div>
        )
      })}
    </div>
  )
}
