import React from "react"
import Container from "../Components/Container/Container"
import Typography from "@material-ui/core/Typography"

const Proximamente = ({ data }) => {
    return (
        <div className="proximamente-container">
            <Container title={data.titulo} more={true}>
                <div>
                    <Typography>{data.texto}</Typography>
                </div>
            </Container>
        </div>
    )
}

export default Proximamente
