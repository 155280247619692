
import jsPDF from 'jspdf'

import React, { useState } from 'react';
import logo from "./../../../Assets/Imagenes/Logo_SUPERCOPA.png"
import QRCode from 'qrcode'
import { useEffect } from 'react';
import moment from 'moment/moment';

const PDFCertificadosAsistencia = (props) => {

  const { alumnos,   listo  } = props;
 
  let Certificados = [];

  alumnos.map((x) => {
    Certificados.push({
      nombre: x.nombre + " " + x.apellido,
      fecha: moment(x.fecha).format("DD/MM/YYYY"),
      dia: x.dia
    })
 
  })

const titulo = (valor) => {
  let sexo = "Mixto"

  switch (valor) {
    case 1:
      sexo = "Sra."
      break;
    case 2:
      sexo = "Sr."
      break;
    case 3:
      sexo = "Sr/a"
      break;

    default:
      sexo = "Sr/a"
      break;
  }
  return sexo
}
 
const mes_name = (num) => {
  let categoria = "n/c"
  switch (num) {

    case "01":
      categoria = "Enero"
      break;
    case "02":
      categoria = "Febrero"
      break;
    case "03":
      categoria = "Marzo"
      break;
    case "04":
      categoria = "Abril"
      break;
    case "05":
      categoria = "Mayo"
      break;
    case "06":
      categoria = "Junio"
      break;
    case "07":
      categoria = "Julio"
      break;
    case "08":
      categoria = "Agosto"
      break;
    case "09":
      categoria = "Septiembre"
      break;
    case "10":
      categoria = "Octubre"
      break;
    case "11":
      categoria = "Noviembre"
      break;
    case "12":
      categoria = "Diciembre"
      break;

    default:
      break;
  }

  return categoria;
}

let doc = new jsPDF({
  orientation: "l",
  unit: "mm",
  format: "a4",
  putOnlyUsedFonts: true,
  precision: 2,
});


let parte = [];
Certificados.map((x, i) => {


  parte = x.fecha.split("/")
  let dia = parte[0]
  let mes = mes_name(parte[1]);
  let anio = parte[2]

  doc.setFontSize(20);
  doc.setFont('Helvetica', 'bold')
  doc.text(`El ${titulo(x.sexo)} ${x.nombre}  `, 50, 75)
  doc.setFontSize(20);
  doc.text(`ha participado en la fecha Nro. ${x.dia} de la Liga `, 50, 90)
  doc.setFontSize(25);
  doc.text(` SUPER COPA TKD. `, 80, 105)
  doc.setFontSize(20);
  doc.text(`el dia ${dia} de ${mes} de ${anio}`, 120, 130)

  if (Certificados.length > i + 1) {

    doc.addPage()
  }



})
doc.output('save', `TITO.pdf`);
// doc.save("yourDoc.pdf");

listo(false)
return (
  <div>
  </div>
);
}

export default PDFCertificadosAsistencia;