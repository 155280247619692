 
import jsPDF from 'jspdf';
import React from 'react';
 import logo from "./../../../Assets/Imagenes/Logo_SUPERCOPA.png"
import PDFPlanillaSalto from './PDFplanillaSalto';
import PDFPlanillaRotura from './PDFplanillaRotura';
import PDFPlanillaLLaveEliminacion from './PDFplanillaLLaveEliminacion';
import PDFplanillaGrupos from './PDFplanillaGrupos';


const PDFPlanillaPrincipal = (props) => {

  const { alumnos, disciplina, categoria,listo} = props;
   let tipo_compe=categoria.tipo_compe;
   let disciplina_id=categoria.disciplina_id
  const NC=(num)=>{
    let categoria="n/c"
    switch (num) {
     
      case 1:
        categoria="Blanco"
        break;
        case 2:
          categoria="Punta Amarilla"
          break;
          case 3:
            categoria="Amarillo"
            break;
            case 4:
              categoria="Punta Verde"
              break;
              case 5:
                categoria="Verde"
                break;
                case 6:
                  categoria="punta Azul"
                  break;
                  case 7:
                    categoria="Azul"
                    break;
                    case 8:
                      categoria="Punta Roja"
                      break;
                      case 9:
                        categoria="Rojo"
                        break;
                    case 10:
                      categoria="Punta Negra"
                      break;
                      case 11:
                        categoria="1º Dan"
                        break;
                        case 12:
                          categoria="2º Dan"
                          break;
                          case 13:
                            categoria="3º Dan"
                            break;
                            case 14:
                              categoria="4º Dan"
                              break;
                              case 15:
                                categoria="5º Dan"
                                break;
                                case 16:
                                  categoria="6º Dan"
                                  break;
                                  case 17:
                                    categoria="7º Dan"
                                    break;
                                    case 18:
                                      categoria="8º Dan"
                                      break; case 19:
                                      categoria="9º Dan"
                                      break;

    
      default:
        break;
    }

  return categoria;
  }
   
  
  return (
    <div>

      { tipo_compe==2 && <PDFplanillaGrupos categoria={ categoria} disciplina={ disciplina} alumnos={ alumnos}  listo={()=>listo()}/>}
    
       { tipo_compe==1 && <PDFPlanillaLLaveEliminacion categoria={ categoria} disciplina={ disciplina} alumnos={ alumnos}  listo={()=>listo()}/>}
      
       {disciplina_id==3&& <PDFPlanillaRotura categoria={ categoria} disciplina={ disciplina} alumnos={ alumnos}  listo={()=>listo()}/>}
    
    
       {disciplina_id==4&& <PDFPlanillaSalto categoria={ categoria} disciplina={ disciplina} alumnos={ alumnos}  listo={()=>listo()}/>}


    </div>
  );
}

export default PDFPlanillaPrincipal;