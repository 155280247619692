import React from 'react';
import IconButton from '@mui/material/IconButton';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import StopIcon from '@mui/icons-material/Stop';
function BtnComponent(props) {



  return (
    <div>
      {(props.status === 0) ?
        <IconButton onClick={props.start} style={{ borderStyle: "solid", borderWidth: 1, color: "aliceblue", borderRadius: 5 }}  ><PlayArrowIcon /></IconButton>
       
        : ""
      }

      {(props.status === 1) ?
        <div>
          <IconButton onClick={props.stop} style={{ borderStyle: "solid", borderWidth: 1, color: "aliceblue", borderRadius: 5 }}  ><PauseIcon /></IconButton>
           </div> : ""
      }

      {(props.status === 2) ?
        <div>
          <IconButton onClick={props.resume} style={{marginRight:10, borderStyle: "solid", borderWidth: 1, color: "aliceblue", borderRadius: 5 }}  ><PlayArrowIcon /></IconButton>
          <IconButton onClick={props.reset} style={{ borderStyle: "solid", borderWidth: 1, color: "aliceblue", borderRadius: 5 }}  ><StopIcon /></IconButton>

        </div> : ""
      }

    </div>
  );
}

export default BtnComponent;