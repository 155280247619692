import React from "react"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import Paper from "@material-ui/core/Paper"
import { makeStyles } from "@material-ui/core/styles"
import IconButton from "@material-ui/core/IconButton"
import DeleteIcon from "@material-ui/icons/Delete"
import LockIcon from "@material-ui/icons/Lock"
import LockOpenIcon from "@material-ui/icons/LockOpen"
import ListIcon from "@material-ui/icons/List"
import { Button } from "@material-ui/core"

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
})

const ExamenTable = ({ data, setModalOpen, setPromoteModalOpen, handleActive, handleEvaluado, navigate }) => {
    const classes = useStyles()

    const tipo = ["Nada", "Gups", "Danes", "Mixto"]

    return (
        <TableContainer component={Paper} style={{ marginTop: "30px" }}>
            <Table className={classes.table} aria-label="exam table" size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Id</TableCell>
                        <TableCell align="center">Fecha</TableCell>
                        <TableCell align="center">Derecho Examen</TableCell>
                        <TableCell align="center">Organizador</TableCell>
                        <TableCell align="center">Tipo</TableCell>
                        <TableCell align="center">Lugar</TableCell>
                        <TableCell align="center">Inscripcion</TableCell>
                        <TableCell align="center">Cerrar Inscripcion</TableCell>
                        {/* <TableCell align="center">Finalizar Examen</TableCell> */}
                        <TableCell align="center">Promover Categorias</TableCell>
                        <TableCell align="center">Inscriptos</TableCell>
                        <TableCell align="center">Borrar</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((fila) => (
                        <TableRow key={fila.id}>
                            <TableCell component="th" scope="row">
                                {fila.id}
                            </TableCell>
                            <TableCell align="center">{fila.fecha}</TableCell>
                            <TableCell align="center">{`$ ${fila.valor_examen}`}</TableCell>
                            <TableCell align="center">{fila.escuela.abreviacion}</TableCell>
                            <TableCell align="center">{tipo[fila.tipo]}</TableCell>
                            <TableCell align="center">{fila.lugar}</TableCell>
                            <TableCell align="center">
                                {fila.inscripcion_activa ? (
                                    <span style={{ color: "green" }}>Activa</span>
                                ) : (
                                    <span style={{ color: "red" }}>Inactiva</span>
                                )}
                            </TableCell>
                            <TableCell align="center">
                                <IconButton onClick={() => handleActive(fila.id)} disabled={fila.evaluado}>
                                    {fila.inscripcion_activa ? <LockOpenIcon /> : <LockIcon />}
                                </IconButton>
                            </TableCell>
                            <TableCell align="center">
                                <Button
                                    style={{ backgroundColor: `${fila.evaluado ? "grey" : "red"}`, fontSize: 10 }}
                                    disabled={fila.evaluado}
                                    onClick={() => setPromoteModalOpen(`${fila.fecha} - ${fila.lugar}`, fila.id)}
                                >
                                    Promover
                                </Button>
                                {/* <IconButton onClick={() => handleEvaluado(fila.id)}> */}
                                {/*   {!fila.evaluado ? <LockOpenIcon /> : <LockIcon />} */}
                                {/* </IconButton> */}
                            </TableCell>
                            <TableCell align="center">
                                <IconButton
                                    onClick={() => navigate(`/director/inscriptos/${fila.id}`)}
                                    // disabled={fila.evaluado}
                                >
                                    <ListIcon />
                                </IconButton>
                            </TableCell>
                            <TableCell align="right">
                                <IconButton onClick={() => setModalOpen(fila.id)} disabled={fila.evaluado}>
                                    <DeleteIcon />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default ExamenTable
