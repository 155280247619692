import React, { useState, useEffect } from "react"
import { makeStyles } from "@material-ui/core/styles"
import List from "@material-ui/core/List"
import { ListItem, Typography } from "@material-ui/core"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import Divider from "@material-ui/core/Divider"
import { fetchResource } from "../../Functions/Functions"
import { useNavigate } from "react-router"

// Iconos
import CollectionsBookmarkRoundedIcon from "@mui/icons-material/CollectionsBookmarkRounded"
import HomeIcon from "@material-ui/icons/Home"
import CheckCircleOutlineSharpIcon from "@material-ui/icons/CheckCircleOutlineSharp"
import AttachMoneyIcon from "@material-ui/icons/AttachMoney"
import BookmarkBorderIcon from "@material-ui/icons/BookmarkBorder"
import SettingsIcon from "@material-ui/icons/Settings"
import FitnessCenterIcon from "@material-ui/icons/FitnessCenter"
import BusinessIcon from "@material-ui/icons/Business"
import SportsKabaddiIcon from "@material-ui/icons/SportsKabaddi"
import PeopleIcon from "@material-ui/icons/People"
import BallotIcon from "@material-ui/icons/Ballot"
import EventIcon from "@material-ui/icons/Event"
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount"
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts"
import EmojiEventsRoundedIcon from '@mui/icons-material/EmojiEventsRounded';


const styles = makeStyles((theme) => ({
    drawerContainer: {
        overflow: "auto",
    },
}))

const DrawerItems = ({ user, handleOpen, mobile, ...props }) => {
    // const navigate =useNavigate()
    const navigate = useNavigate()
    const classes = styles()

    const [examen, setExamen] = useState(null)

    useEffect(() => {
        const fetchExamen = async () => {
            const [data, error] = await fetchResource("/users/examen/showUser", "get", true, null)
            if (data) {
                setExamen(data.examen)
            } else {
                console.error(error)
            }
        }
        fetchExamen()
    }, [])

    var evalua = true

    return (
        <div className={classes.drawerContainer}>
            <List onClick={mobile ? () => handleOpen() : () => { }}>
                <ListItem>
                    <Typography variant="h6">Usuario</Typography>
                </ListItem>
                <ListItem onClick={() => navigate("/")} button key={1}>
                    <ListItemIcon>
                        <HomeIcon />
                    </ListItemIcon>
                    <ListItemText primary="Inicio" />
                </ListItem>
                <ListItem onClick={() => navigate("/usuarios/asistencias")} button key={2}>
                    <ListItemIcon>
                        <CheckCircleOutlineSharpIcon />
                    </ListItemIcon>
                    <ListItemText primary="Mi Asistencia" />
                </ListItem>
                <ListItem onClick={() => navigate("/usuarios/cuotas")} button key={3}>
                    <ListItemIcon>
                        <AttachMoneyIcon />
                    </ListItemIcon>
                    <ListItemText primary="Mi Cuota" />
                </ListItem>
                <ListItem onClick={() => navigate("/usuarios/matricula")} button key={4}>
                    <ListItemIcon>
                        <BookmarkBorderIcon />
                    </ListItemIcon>
                    <ListItemText primary="Mi Matricula" />
                </ListItem>
                {/* <ListItem onClick={() => navigate('/usuarios/libreta')} button key={5}>
                    <ListItemIcon><MenuBookIcon/></ListItemIcon>
                    <ListItemText primary="Mi Libreta" />
                </ListItem>   */}
                <ListItem onClick={() => navigate("/profile")} button key={6}>
                    <ListItemIcon>
                        <SettingsIcon />
                    </ListItemIcon>
                    <ListItemText primary="Perfil" />
                </ListItem>
                <ListItem onClick={props.logOut} button key={7}>
                    <ListItemIcon>🚪</ListItemIcon>
                    <ListItemText primary="Salir" />
                </ListItem>
            </List>
            <Divider />

            {user.role_id < 4 && (
                <List onClick={mobile ? () => handleOpen() : () => { }}>
                    <ListItem>
                        <Typography variant="h6">Instructor</Typography>
                    </ListItem>
                    <ListItem onClick={() => navigate("/alumnos")} button key={2}>
                        <ListItemIcon>
                            <SportsKabaddiIcon />
                        </ListItemIcon>
                        <ListItemText primary="Alumnos" />
                    </ListItem>
                    <ListItem onClick={() => navigate("/alumnosSinInstructor")} button key={6}>
                        <ListItemIcon>
                            <SportsKabaddiIcon />
                        </ListItemIcon>
                        <ListItemText primary="Alumnos Sin Instructor" />
                    </ListItem>

                    <ListItem onClick={() => navigate("/actividadesExamenes")} button key={3}>
                        <ListItemIcon>
                            <BallotIcon />
                        </ListItemIcon>
                        <ListItemText primary="Actividades" />
                    </ListItem>

                    <ListItem onClick={() => navigate("/instructor/HomeExamenes")} button key={4}>
                        <ListItemIcon>
                            <CollectionsBookmarkRoundedIcon />
                        </ListItemIcon>
                        <ListItemText primary="Examenes" />
                    </ListItem>

                    <ListItem onClick={() => navigate("/centros")} button key={5}>
                        <ListItemIcon>
                            <BusinessIcon />
                        </ListItemIcon>
                        <ListItemText primary="Centros" />
                    </ListItem>
                    <ListItem onClick={() => navigate("/clases")} button key={6}>
                        <ListItemIcon>
                            <FitnessCenterIcon />
                        </ListItemIcon>
                        <ListItemText primary="Clases" />
                    </ListItem>

                    {/* {evalua == true &&
                        <ListItem style={{ background: "green" }} onClick={() => console.log("DD")} button key={6}>
                            <ListItemIcon><BallotIcon /></ListItemIcon>
                            <ListItemText primary="EVALUAR" />
                        </ListItem>
                    } */}

                    {/* <ListItem onClick={()=>history.push('/instructor/inscripcionExamen')} button key={7}>
                    <ListItemIcon><BallotIcon/></ListItemIcon>
                    <ListItemText primary="Inscripcion a Examen" />
                     </ListItem>   */}
                    {/* <ListItem onClick={()=>history.push('/')} button key={1}>
                    <ListItemIcon><AddShoppingCartIcon/></ListItemIcon>
                    <ListItemText primary="Indumentaria" />
                </ListItem> */}
                </List>
            )}
            {user.role_id < 3 && (
                <List onClick={mobile ? () => handleOpen() : () => { }}>
                    <Divider />
                    <ListItem>
                        <Typography variant="h6">Director</Typography>
                    </ListItem>
                    {/* <ListItem onClick={() => history.push('/instructores')} button key={2}>
                        <ListItemIcon><PeopleIcon /></ListItemIcon>
                        <ListItemText primary="Instructores" />
                    </ListItem> */}

                    {/* <ListItem onClick={() => history.push('/pagos')} button key={3}>
                        <ListItemIcon><AttachMoneyIcon /></ListItemIcon>
                        <ListItemText primary="Pagos" />
                    </ListItem> */}

                    <ListItem onClick={() => navigate("/directores/matriculas")} button key={4}>
                        <ListItemIcon>
                            <BookmarkBorderIcon />
                        </ListItemIcon>
                        <ListItemText primary="Matriculas" />
                    </ListItem>
                    <ListItem onClick={() => navigate("/directores/actividades")} button key={5}>
                        <ListItemIcon>
                            <EventIcon />
                        </ListItemIcon>
                        <ListItemText primary="Actividades" />
                    </ListItem>

                    <ListItem onClick={() => navigate("/examen")} button key={6}>
                        <ListItemIcon>
                            <EventIcon />
                        </ListItemIcon>
                        <ListItemText primary="Examen" />
                    </ListItem>

                    {/* <ListItem style={{ background: "green" }} onClick={() => history.push('/director/controlEvaluacion')} button key={7}>
                            <ListItemIcon><BallotIcon /></ListItemIcon>
                            <ListItemText primary="EVALUACION" />
                        </ListItem> */}
                </List>
            )}

            {user.role_id < 2 && (
                <List onClick={mobile ? () => handleOpen() : () => { }}>
                    <Divider />
                    <ListItem>
                        <Typography variant="h6">Presidente</Typography>
                    </ListItem>
                    <ListItem onClick={() => navigate("/directores")} button key={2}>
                        <ListItemIcon>
                            <SupervisorAccountIcon />
                        </ListItemIcon>
                        <ListItemText primary="Directores" />
                    </ListItem>
                    {/* <ListItem onClick={()=>navigate('/')} button key={3}>
                    <ListItemIcon><AttachMoneyIcon/></ListItemIcon>
                    <ListItemText primary="Pagos" />
                </ListItem>
                <ListItem onClick={()=>navigate('/')} button key={4}>
                    <ListItemIcon><SchoolIcon/></ListItemIcon>
                    <ListItemText primary="Escuelas" />
                </ListItem>
                <ListItem onClick={()=>navigate('/')} button key={5}>
                    <ListItemIcon><PolicyIcon/></ListItemIcon>
                    <ListItemText primary="Asociaciones" />
                </ListItem>
                <ListItem onClick={()=>navigate('/')} button key={6}>
                    <ListItemIcon><PermDataSettingIcon/></ListItemIcon>
                    <ListItemText primary="Estadisticas" />
                </ListItem>
                <ListItem onClick={()=>navigate('/')} button key={6}>
                    <ListItemIcon><BuildIcon/></ListItemIcon>
                    <ListItemText primary="Administracion" />
                </ListItem> */}

                    <Divider />
                    <ListItem>
                        <Typography variant="h6">Administrador</Typography>
                    </ListItem>
                    <ListItem onClick={() => navigate("/directores")} button key={3}>
                        <ListItemIcon>
                            <SettingsIcon />
                        </ListItemIcon>
                        <ListItemText primary="Configuración" />
                    </ListItem>
                    <ListItem onClick={() => navigate('/liga')} button key={3}>
                        <ListItemIcon><EmojiEventsRoundedIcon /></ListItemIcon>
                        <ListItemText primary="LIGA" />
                    </ListItem>
                    


                </List>)}
        </div>
    )
}

export default DrawerItems
