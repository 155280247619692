import { Button, CircularProgress, Input, MenuItem, Select, TextField, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles';
import { DataGrid } from '@mui/x-data-grid'
import React, { useState } from 'react'
// import Containter from '../../../Components/Container/Container';
import MatchEliminacion from './MatchEliminacion';

const LLaveActiva = (props) => {
    const { datos,
        handleWin,
        cancelMatch,
        finalizar,
        activeMatch,
        handleAsist,
        listoXausente
    } = props
    const [showMatch, setShowMatch] = useState(false);
    const [competidores, setCompetidores] = useState([])
    const [area, setArea] = useState(0);
    console.log("LLAVE ACTIVA RECIBE ", datos )
   
    let llave = datos.llave
    let rondas = datos.rondas;
    let estado = datos.estado;
    let altoPosicion = 40;
    let font = 13;
    let largoPosicion = 300;
    let espaciado = altoPosicion / 2

    const estadoEstado = () => {
        let x = false
        if (estado == 3) { x = true }
        return x
    }
    const matchcorrecto = (datos) => {
    
        let compMatch = llave.filter((x) => x.match_id == datos.match_id  && x.competidor_id>0)
         
        if (compMatch.length == 2){
            setCompetidores(compMatch)
            setShowMatch(true)
            activeMatch(true)

        }
      
       
    }
    const posicionResultado = (llave) => {

        let primero = [];
        let segundo = [];
        let margenB = 20;

        llave.map((x) => {
                if (x.next == 0) {
                    if (x.resultado == 1) {
                        primero = { ...x }
                    }
                    else if (x.resultado == 0) { segundo = { ...x } }
                }
         
        })


        return (<div style={{ display: "flex", flexDirection: "column", height: "50%",justifyContent:"center" }}>

            {primero.nombre && <Button disabled={estadoEstado()} style={{ background: "red", marginBottom: 50 }} onClick={() => finalizar({ categoria_id: primero.categoria_id, liga_id: primero.liga_id, dia: primero.dia })} >{estado == 3 ? "LLAVE FINALIZADA" : "FINALIZAR"}</Button>}

            <div style={{ marginBottom: 80, display: "flex", flexDirection: "column", alignItems: "center", marginBottom: margenB, width: largoPosicion + 50, height: altoPosicion }}>
                <Typography variant='h3' style={{ textAlign: "center", color: "limegreen" }}> {"1º PUESTO "}</Typography>
                <Typography variant='h4' style={{ textAlign: "center" }}> {primero.nombre ? primero.nombre+" "+primero.apellido  : " - -"}</Typography>

            </div>


            <div style={{ marginTop: 100, display: "flex", flexDirection: "column", alignItems: "center", marginBottom: margenB, width: largoPosicion + 50, height: altoPosicion }}>

                <Typography variant='h4' style={{ textAlign: "center", color: "limegreen" }} >  {"2º PUESTO"}</Typography>
                <Typography variant='h5' style={{ textAlign: "center" }} > {segundo.nombre ? segundo.nombre+" "+segundo.apellido: "- -"}</Typography>

            </div>

        </div>
        )
    }
    const posicion = (datos, ronda) => {

        let repite = llave.filter((x) => x.competidor_id > 0 && x.competidor_id == datos.competidor_id)
        let r = repite.reduce((acc, el) => acc.ronda < el.ronda ? acc : el, [])

       
        let margenL = 30;
        let margenB = 0
        let tamañollave = altoPosicion
        let margenT = 100;
        let color = "white"
        let compe =datos.competidor_id>0? datos.nombre +" "+datos.apellido :null;
        let bordeInferior = "solid"
        if (datos.posicion % 2 !== 1) {
            if (ronda == 1) { margenL = margenL; margenB = espaciado; margenT = 0; }
            if (ronda == 2) { margenL = margenL; margenB = espaciado * 3; tamañollave = espaciado * 5 }
            if (ronda == 3) { margenL = margenL; margenB = espaciado * 8; tamañollave = espaciado * 10 }
            if (ronda == 4) { margenL = margenL; margenB = espaciado * 17; tamañollave = espaciado * 21 }
            if (ronda == 5) { margenL = margenL ; margenB = espaciado * 16; tamañollave = espaciado * 42 }

            bordeInferior = "solid"
        } else { bordeInferior = "none" }
        return (

            <div style={{ alignItems: "flex-end", borderBottomStyle: "solid", borderRightStyle: bordeInferior, display: "flex", flexDirection: "row", marginBottom: margenB, width: largoPosicion, height: tamañollave }}>
               
                {datos.competidor_id > 0 &&
                    <Button disabled={estadoEstado()}
                        onClick={() => cancelMatch(datos)}  > X
                    </Button>
                }
          
                <Button
                    disabled={ estadoEstado() }
                    onClick={() => { matchcorrecto(datos) }}
                    style={{ cursor: estado == 2 ? "default" : "pointer", textAlign: "initial", fontSize: font }}
                > {compe!== null ? compe : "-  -"}

                </Button>
            </div>

        )
    }
    const datosLLave = (match) => { //match id del match
        let comp = []

        datos.llave.map((x) => {

            if (x.match_id == match && x.presente > 0) {
                comp.push(x)
            }
        })

        if (comp.length < 2) {
            comp = []
        }
       
        setCompetidores(comp)
        setShowMatch(true)
        activeMatch(true)

    }
    const handleCancelMatch = () => {
        setShowMatch(false)
        activeMatch(false)
    }
    const listo = (x) => {

        handleWin(x)
        setShowMatch(false)
        activeMatch(false)
    }

    return (
       
     
     
        <div style={{ display: "flex", flexDirection: "row", width: "100%" ,marginTop:"40px"}}   >
 
            {showMatch == true ? <MatchEliminacion listo={(x) => listo(x)} datos={competidores} tipoCompe={1} cancelButton={() => handleCancelMatch()} /> :
                <>
                    <div  >
                        {llave.map((x) => {
                            if (x.ronda == 1) {
                                return (posicion(x, 1))
                            }
                        })}
                    </div>

                    <div style={{ paddingTop: espaciado * 1 }}>
                        {llave.map((x) => {
                            if (x.ronda == 2) {
                                return (posicion(x, 2))
                            }

                        })}
                    </div>

                    <div style={{ paddingTop: espaciado * 3.5 }}>
                        {llave.map((x) => {
                            if (x.ronda == 3) {
                                return (posicion(x, 3))
                            }
                        })}
                    </div>

                    <div style={{ paddingTop: espaciado * 8 }}>
                        {llave.map((x) => {
                            if (x.ronda == 4) {
                                return (posicion(x, 4))
                            }
                        })}


                    </div>

                    <div style={{ paddingTop: espaciado * 16 }}>
                        {llave.map((x) => {

                            if (x.ronda == 5) {
                                return (posicion(x, 5))
                            }


                        })}
                    </div>

                    <div style={{ marginLeft: 50, paddingTop: ((altoPosicion / 2) + 10) * 3 }}>
                        {posicionResultado(llave, rondas)}
                    </div>

                </>}
        </div>
         
    )
}
export default LLaveActiva