import { Button, CircularProgress, Input, MenuItem, Select, TextField, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles';
import { DataGrid } from '@mui/x-data-grid'
import React, { useState } from 'react'
import Containter from '../../../Components/Container/Container';
import MatchRotura from './MatchRotura';

const LLavePlanillaSalto = (props) => {
    const { datos,
        handleWin,
        cancelMatch,
        finalizar,
        HandlePuesto,
        siguienteRonda,
        presente


    } = props
    const [competidor, setCompetidor] = useState(datos.llave[0])
    const [showMatch, setShowMatch] = useState(false);

    let llave = datos.llave;
    let estado = datos.estado;
    let altoPosicion = 40;
  
  console.log("PLANILLA", datos)
  
  const estadoEstado = () => {
        let x = false
        if (estado == 3) { x = true }
        return x
    }

    const handleChangeAsistencia = (x) => {

        let compe = { ...x, presente: !x.presente }
        console.log(compe)
        presente(compe)

    }

    const handleChangePuesto = (e, x) => {
        let puesto = e.target.value
        let compe = { ...x, resultado: puesto == 0 ? null : puesto }
        console.log(compe)
        HandlePuesto(compe)

    }

    const cancel = () => {

        setShowMatch(false)
    }

    const selectCompetidor = (x) => {

        setCompetidor(x)
        setShowMatch(true)

    }

    return (
        <div style={{ display: "flex", flexDirection: "column", width: "100%", borderStyle: "solid", height: 700 }}   >


            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", width: "100%", borderStyle: "solid", height: 100 }}   >

                <Button disabled={estado == 3 ? true : false}
                    onClick={() => finalizar({ categoria_id: llave[0].categoria_id, liga_id: llave[0].liga_id, dia: llave[0].dia })}
                    style={{ background: "red", height: 60, width: "20%", marginLeft: 20, width: 100 }}>FINALIZAR</Button>



            </div>
            <div style={{ display: "flex", flexDirection: "row", width: "100%", height: 50 }}>
                <Typography style={{ width: 50, textAlign: "center" }}>Nro</Typography>
                <Typography style={{ width: 300, textAlign: "center" }}>Nombre</Typography>
                <Typography style={{ width: 100, textAlign: "center", marginLeft: 30 }}>Puesto</Typography>
                <Typography style={{ width: 100, textAlign: "center" }}>Categoria</Typography>
                <Typography style={{ width: 100, textAlign: "center" }}>EDAD</Typography>
            </div>


            {llave.map((x, i) => {

                return (

                    <div style={{ display: "flex", flexDirection: "row", width: "100%", height: 50, borderBottomStyle: "solid", borderBottomColor: "GrayText", borderBottomWidth: 1 }}>
                        <Typography style={{ width: 50, textAlign: "center", alignContent: "center" }}>{i + 1}</Typography>

                        <Typography style={{ width: 300, textAlign: "center", alignContent: "center", color: "white" }}>
                            {x.nombre + " " + x.apellido}
                        </Typography>

                        <div style={{ marginLeft: 30, width: 100, alignContent: "center" }}>

                            <Select
                                disabled={estado == 3 ? true : false}
                                style={{ width: 100, fontSize: 30, textAlign: "center", color: estado == 2 ? "gray" : "white" }}
                                value={x.resultado == null ? 0 : x.resultado}
                                name={"puesto"}
                                onChange={(e) => handleChangePuesto(e, x)}
                            >
                                <MenuItem id={0} value={0}>--</MenuItem>
                                <MenuItem id={1} value={1}>1º</MenuItem>
                                <MenuItem id={2} value={2}>2º</MenuItem>
                                <MenuItem id={3} value={3}>3º</MenuItem>


                            </Select>
                        </div>
                        <Typography style={{ width: 100, textAlign: "center", alignContent: "center", fontSize: 10 }}>{x.categoria}</Typography>
                        <Typography style={{ width: 100, textAlign: "center", alignContent: "center" }}>{x.edad}</Typography>

                    </div>
                )
            })}




        </div>

    )
}
export default LLavePlanillaSalto
