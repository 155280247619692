import { Button, FormControlLabel, Input, MenuItem, Select, TextField, Typography, IconButton, Tooltip, CircularProgress, Modal } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles';
import React, { useEffect, useState } from 'react'
import Containter from '../../../Components/Container/Container';
import { DatePicker } from '@material-ui/pickers';
import moment from 'moment';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate } from 'react-router'
import { useLocation } from 'react-router-dom';

const Styles = makeStyles((theme) => ({
    paper: {
        [theme.breakpoints.down("sm")]: {
            top: "50vh",
            left: "1vw",
        },
        position: "absolute",
        top: "40vh",
        left: "50vw",
        minWidth: 370,
        maxWidth: 400,
        padding: 10,
        background: "linear-gradient(to right,  #101010, #151515 , #202020 , #303030 , #434343)",
        boxShadow: 10,
        borderRadius: 10,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    },
    alertText: {
        color: "#e00",
    },
    text: {
        textAlign: "center",
    },
    button: {
        width: 80,
        backgroundColor: "#e00",
        margin: 15,
    },
    buttonSection: {
        display: "flex",
    },
}))
const LigaInfoGeneral = (props) => {
    const { handleCancelaDia, info, handleIniciaDia } = props
    console.log("info", info)
    const navigate = useNavigate()
    useEffect(() => {
        fetchShowLiga(info.liga.id);
    }, [info])

    const clases = Styles()
    const [loading, setLoading] = useState(true)
    const [ligaData, setLigaData] = useState()
    const [modalOpen, setModalOpen] = useState(false)
    const [diaFinalizar, setDiaFinalizar] = useState(0)
    let array = []
    const [params, setParams] = useState({
        id: 2,
        dias: array,
        cantDias: 0,
        categorias: [],
        InscripcionActiva: 0,

    })

    const notify = (x, nombre) => {
        if (x == 1) {
            toast.success(nombre + '.', { style: { background: 'lightgreen', color: "Black" } });
        } else {
            toast.error(nombre + '.', { style: { background: 'red', color: "white" } });
        }
    }

    const NombreDia = (d) => {
        let dia = ""

        switch (d) {
            case 0:
                dia = "Domingo"
                break;
            case 1:
                dia = "Lunes"
                break;
            case 2:
                dia = "Martes"
                break;
            case 3:
                dia = "Miercoles"
                break;
            case 4:
                dia = "Jueves"
                break;
            case 5:
                dia = "Viernes"
                break;
            case 6:
                dia = "Sabado"
                break;

            default:
                dia = "Desconocido"
                break;
        }


        return dia
    }

    const fetchShowLiga = async (id) => {
        setLoading(true)
        try {

            let res = await fetch(`${process.env.REACT_APP_BACKEND_LIGA}/showLiga/${id}`,
                {
                    method: "GET",
                    headers: { 'Content-type': 'application/json' },

                })

            let response = await res.json()

            if (response.code == 200) {
                let liga = response.data.ligas[0];
                let fechas = response.data.fechas

                fetchInfoLiga(id, liga, fechas);


            } else if (response.code == 400) {

                setLoading(false)
                console.log(response)
            }
        } catch (error) {
            setLoading(false)
            console.log(error)
        }

    }

    const fetchInfoLiga = async (id, liga, fechas) => {
        setLoading(true)

        try {
            let res = await fetch(`${process.env.REACT_APP_BACKEND_LIGA}/infoLiga/${id}`,
                {
                    method: "GET",
                    headers: { 'Content-type': 'application/json' },
                })

            let response = await res.json()

            if (response.code == 200) {


                setLigaData({
                    liga: response.data.liga,
                    fechas: response.data.fechas, llaves: response.data.llaves, competidores: response.data.competidores
                })
                //    console.log("INFO",{liga:liga,fechas:fechas , llaves:response.data.llaves, competidores: response.data.competidores})   

                setLoading(false)
            } else if (response.code == 400) {
                console.log("INFO NONO")
                setLoading(false)
                console.log(response)
            }
        } catch (error) {
            setLoading(false)
            console.log(error)
        }

    }
    const pressCrearLiga = () => {



        notify(0, "ERROR, Faltan parametros para Crear la Liga")


    }

    const disponibleParaActivar = (f) => {
        let mensaje = ""
        let estado = false
        let color = "red"

        // let activa = ligaData.fechas.filter((x) => x.terminado === 0 && x.iniciada === 1 && x.liga_id === ligaData.liga.id)
        // let disponible = ligaData.fechas.filter((x) => x.terminado === 0 && x.iniciada === 0 && x.liga_id === ligaData.liga.id)
        // let terminado = ligaData.fechas.filter((x) => x.terminado === 1 && x.iniciada === 1 && x.liga_id === ligaData.liga.id)

        // disponible.sort((a, b) => a.dia_num - b.dia_num)
        // let diaActivar = disponible[0].dia_num

        // if (activa.length > 0) {

            if (f.terminado === 0 && f.iniciada === 1    ) {
                estado = false
                mensaje = ">> EN CURSO << "
                color = " green"
            }  else {
                estado = false
                mensaje = "Activar"
                color = "orange"

            }
 

        // } else {

        //     if (diaActivar === f.dia_num) {
        //         estado = false
        //         mensaje = " Activar "
        //         color = "green"

        //     }

        //     if (f.terminado === 1 && f.iniciada === 1 && f.liga_id === ligaData.liga.id) {
        //         estado = true
        //         mensaje = " Finalizado "
        //         color = "green"

        //     }

        //     if (f.terminado === 0 && f.iniciada === 0 && f.liga_id === ligaData.liga.id && diaActivar !== f.dia_num) {
        //         estado = true
        //         mensaje = " PENDIENTE "
        //         color = "red"
        //     }


        // }




        return { activado: estado, mensaje: mensaje, color: color }
    }
    const pressActions = (datos) => {
       
        if (datos.terminado === 0 && datos.iniciada === 0) {
            handleIniciaDia(datos.dia_num)
        } else if (datos.terminado === 0 && datos.iniciada === 1) {
           setModalOpen(true)
           setDiaFinalizar(datos.dia_num) 
        }


    }
    const finalizaFecha=()=>{
       
        handleCancelaDia(diaFinalizar)
        setModalOpen(false)
    }

    return (

        <div className={clases.tableContainer}>
            {/* <Toaster position="center-center" /> */}

            {!loading ?

                <div className={clases.seccion}  >
                    <Typography style={{ width: "100%", textAlign: "center", fontSize: 25, background: "red", color: "white" }} variant="body1">INFORMACION de LIGA  </Typography>
                    <Typography variant='h6' style={{ width: "100%", paddingLeft: "10%", paddingTop: 20, textAlign: "left" }} >LIGA Nro.  {ligaData.liga.id}  </Typography>
                    <Typography variant='h6' style={{ width: "100%", paddingLeft: "10%", paddingTop: 20, textAlign: "left" }} >NOMBRE:   {ligaData.liga.nombre}  </Typography>
                    <Typography style={{ width: "100%", textAlign: "left", paddingLeft: "10%", paddingTop: 20 }} variant="h6">Fechas de Competencia:  {ligaData.fechas.length}</Typography>
                    {ligaData.fechas.length > 0 ?
                        <>
                            {ligaData.fechas.map((x) => {
                                return (<div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "center" }}>
                                    <div style={{ width: "100%", alignContent: "center", display: "flex", flexDirection: "row" }}>
                                        <Typography style={{ width: "30%", textAlign: "right", paddingRight: 5, color: x.terminado == 1 ? "GrayText" : "white" }} variant="h6">{"Fecha " + x.dia_num}   </Typography>
                                        <Typography style={{ width: "20%", textAlign: "center", paddingRight: 5, color: x.terminado == 1 ? "GrayText" : "white" }} variant="h6">{NombreDia(moment(x.fecha).days())}</Typography>
                                        <Typography style={{ width: "20%", textAlign: "left", paddingRight: 5, color: x.terminado == 1 ? "GrayText" : "white" }} variant="h6">  {moment(x.fecha).format("DD-MM-YYYY")}</Typography>
                                        <Button
                                            onClick={() => pressActions(x)}
                                            disabled={disponibleParaActivar(x).activado}
                                            style={{ width: "10%", textAlign: "left", paddingRight: 5, color: disponibleParaActivar(x).color }} >
                                            {disponibleParaActivar(x).mensaje}</Button>
                                    </div>
                                </div>
                                )
                            })}

                            <Typography style={{ width: "100%", textAlign: "left", paddingLeft: "10%", paddingTop: 20 }} variant="h6">Disciplinas:</Typography>

                            <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "center" }}>
                                <div style={{ width: "100%", alignContent: "center", display: "flex", flexDirection: "row" }}>
                                    <Typography style={{ width: "30%", textAlign: "right", paddingRight: 5 }} variant="body2">Disciplina  </Typography>
                                    <Typography style={{ width: "20%", textAlign: "center", paddingRight: 5 }} variant="body2">c/llave </Typography>
                                    <Typography style={{ width: "20%", textAlign: "left", paddingRight: 5 }} variant="body2"> s/llave  </Typography>
                                    <Typography style={{ width: "10%", textAlign: "left", paddingRight: 5 }} variant="body2"> estado  </Typography>
                                </div>
                            </div>
                            {ligaData.llaves.map((x) => {
                                return (<div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "center" }}>
                                    <div style={{ width: "100%", alignContent: "center", display: "flex", flexDirection: "row" }}>
                                        <Typography style={{ width: "30%", textAlign: "right", paddingRight: 5, color: x.terminado == 1 ? "GrayText" : "white" }} variant="h6">{x.nombre}   </Typography>
                                        <Typography style={{ width: "20%", textAlign: "center", paddingRight: 5, color: x.terminado == 1 ? "GrayText" : "white" }} variant="h6">{x.con_llave}  </Typography>
                                        <Typography style={{ width: "20%", textAlign: "left", paddingRight: 5, color: x.terminado == 1 ? "GrayText" : "white" }} variant="h6"> {x.sin_llave}  </Typography>
                                        <Typography style={{ width: "10%", textAlign: "left", paddingRight: 5, color: x.sin_llave == 0 ? "green" : "red" }} variant="h6">{x.sin_llave == 0 ? "LISTO" : "X"}  </Typography>
                                    </div>
                                </div>
                                )
                            })}


                        </>
                        :
                        <Typography style={{ width: "100%", textAlign: "center", padding: 20 }} variant="body1">NO HAY FECHAS REGISTRADAS</Typography>
                    }
                    {/* <Button className={clases.boton}  disabled={2==1?true:false} onClick={()=>handleInicia()}   >{"INICIAR LIGA"}</Button> */}
                </div>
                : <CircularProgress />

            }


            <Modal open={modalOpen}>
                <div className={clases.paper}>
                    <Typography variant='h3'>FECHA {info.diaActivar}</Typography>

                    <Typography>¿quiere Finalizar la fecha?</Typography>

                    <div style={{ display: "flex", msFlexDirection: "row", width: "100%", justifyContent: "center", marginTop: 30 }}>

                        <Button
                        onClick={()=>finalizaFecha()}
                         style={{ backgroundColor: "darkgreen", margin: 15 }}>Finalizar</Button>
                        <Button
                            onClick={() => setModalOpen(false)}
                            style={{ backgroundColor: "red", margin: 15 }}>Cancelar</Button>
                    </div>

                </div>


            </Modal>

        </div>




    )
}


export default LigaInfoGeneral