import { Button, ButtonBase, IconButton, Input, MenuItem, Select, TextField, Tooltip, Typography, Modal } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles';
import Selection from '../../../Components/Formularios/Select';
import React, { useState } from 'react'

const Styles = makeStyles({
    form: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 20,
        gap: '1em',
    },
    button: {
        width: '210px',
        backgroundColor: '#e00'
    },
    registro: {
        borderTopStyle: 'solid',
        borderColor: '#fffb',
        borderWidth: '1px',
        paddingTop: '5px',
        marginTop: '5px',
        marginBottom: '5px',
    },
    forgot: {
        textDecoration: 'none',
        color: '#0099ff',
    },
})





const Edit_Inscriptos = ({ value, datos, loading, handleWin, cancelMatch, changePage, handleSubmit, cerrar }) => {
    
     const [values, setValues] = useState(value ?value:
        {
        dni: "",
        nombre: "",
        apellido: "",
        sexo: "",
        categoria_id: 1,
        categoria: "",
        instructor: "",
        instructor_id: 0,
        edad: 0,
        peso: 0,
        altura: 0

    });

    const NC = (num) => {
        let categoria = "n/c"
        switch (num) {

            case 1:
                categoria = "Blanco"
                break;
            case 2:
                categoria = "Punta Amarilla"
                break;
            case 3:
                categoria = "Amarillo"
                break;
            case 4:
                categoria = "Punta Verde"
                break;
            case 5:
                categoria = "Verde"
                break;
            case 6:
                categoria = "punta Azul"
                break;
            case 7:
                categoria = "Azul"
                break;
            case 8:
                categoria = "Punta Roja"
                break;
            case 9:
                categoria = "Rojo"
                break;
            case 10:
                categoria = "Punta Negra"
                break;
            case 11:
                categoria = "1º Dan"
                break;
            case 12:
                categoria = "2º Dan"
                break;
            case 13:
                categoria = "3º Dan"
                break;
            case 14:
                categoria = "4º Dan"
                break;
            case 15:
                categoria = "5º Dan"
                break;
            case 16:
                categoria = "6º Dan"
                break;
            case 17:
                categoria = "7º Dan"
                break;
            case 18:
                categoria = "8º Dan"
                break; case 19:
                categoria = "9º Dan"
                break;


            default:
                break;
        }

        return categoria;
    }

    const handleChange = (x) => {
        let valor = x.target.value;
        let nombre = x.target.name;

        switch (nombre) {
            case "dni":
                setValues({ ...values, dni: valor })
                break;
            case "nombre":
                setValues({ ...values, nombre: valor })
                break;
            case "apellido":
                setValues({ ...values, apellido: valor })
                break;
            case "sexo":
                setValues({ ...values, sexo: valor })
                break;
            case "categoria_id":
                setValues({ ...values, categoria_id: valor, categoria: NC(valor) })
                break;
            case "instructor":
                setValues({ ...values, instructor_id: 0, instructor: valor })
                break;
            case "peso":
              
                    setValues({ ...values, peso: valor>=0 &&valor<180 ?valor:values.peso })
            
                break;
            case "edad":
              
                    setValues({ ...values, edad:  valor>=0 &&valor<99 ?valor:values.edad })
              

                break;
            case "altura":
               
                    setValues({ ...values, altura:  valor>=0 &&valor<220 ?valor:values.altura})
                
                break;

            default:
                break;
        }




    }
    const sexoOpciones = [
        { id: 2, nombre: "Masculino" },
        { id: 1, nombre: "Femenino" }
    ]

    const categorias = [
        { id: 1, categoria_id: 1, nombre: "Blanco" },
        { id: 2, categoria_id: 2, nombre: "Punta Amarilla" },
        { id: 3, categoria_id: 3, nombre: "Amarillo" },
        { id: 4, categoria_id: 4, nombre: "Punta Verde" },
        { id: 5, categoria_id: 5, nombre: "Verde" },
        { id: 6, categoria_id: 6, nombre: "Punta Azul" },
        { id: 7, categoria_id: 7, nombre: "Azul" },
        { id: 8, categoria_id: 8, nombre: "Punta Roja" },
        { id: 9, categoria_id: 9, nombre: "Rojo" },
        { id: 10, categoria_id: 10, nombre: "Punta Negra" },
        { id: 11, categoria_id: 11, nombre: "I Dan" },
        { id: 12, categoria_id: 12, nombre: "II Dan" },
        { id: 13, categoria_id: 13, nombre: "III Dan" },
        {  id: 14,categoria_id: 14, nombre: "IV Dan" },
        {  id: 15,categoria_id: 15, nombre: "V Dan" },
        {  id: 16,categoria_id: 16, nombre: "VI Dan" },
        { id: 17, categoria_id: 17, nombre: "VII Dan" },
        { id: 18, categoria_id: 18, nombre: "VIII Dan" },
        { id: 19, categoria_id: 19, nombre: "XI Dan" },


    ]

    const style = Styles()


    return (


        <div style={{ marginLeft: "20%", width: "60%", height: "80%", background: "black" }} >

                <Typography variant="h4" style={{padding:50, textAlign:"center"}}>DATOS DE INSCRIPCION</Typography>

            <form  className={style.form} autoComplete="nope">
                <TextField autoComplete="nope" id="dni" value={values.dni} onChange={handleChange} name="dni" type="text" variant="outlined" size="small" label="Dni" color="secondary" />
                <TextField autoComplete="nope" id="nombre" value={values.nombre} onChange={handleChange} name="nombre" type="text" variant="outlined" size="small" label="Nombre" color="secondary" required />
                <TextField autoComplete="nope" id="apellido" value={values.apellido} onChange={handleChange} name="apellido" type="text" variant="outlined" size="small" label="Apellido" color="secondary" required />
                <Selection autoComplete="nope" id="sexo" name="sexo" value={values.sexo} options={sexoOpciones} onChange={handleChange} label="Sexo" color="secondary" required />

                <Selection autoComplete="nope" id="categoria_id" name="categoria_id"
                    value={values.categoria_id} options={categorias} onChange={handleChange} label="Categoria" color="secondary" required />
                <TextField autoComplete="nope" id="instructor" value={values.instructor} onChange={handleChange} name="instructor" type="text" variant="outlined" size="small" label="Instructor" color="secondary" required />

                <TextField autoComplete="nope" id="edad" value={values.edad} label="Edad" onChange={handleChange} name="edad" type="number" variant="outlined" size="small" color="secondary" required />
                <TextField autoComplete="nope" id="peso" value={values.peso} label="Peso (kg.)" onChange={handleChange} name="peso" type="number" variant="outlined" size="small" color="secondary" required />
                <TextField autoComplete="nope" id="altura" value={values.altura} label="Altura (cm)" onChange={handleChange} name="altura" type="number" variant="outlined" size="small" color="secondary" required />


                <Button type="Button" variant="contained"  onClick={() =>{handleSubmit(values);cerrar(false)}}className={style.button} style={{ background: "green" }} color="primary">Guardar</Button>
                <Button type="Button" onClick={() => cerrar(false)} variant="contained" className={style.button} color="primary">Cancelar</Button>
            </form>


        </div>

    )
}

export default Edit_Inscriptos