import { MenuItem, TextField, Typography, Button, CircularProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles';
import { DataGrid } from '@mui/x-data-grid'
import React, { useEffect, useState } from 'react'
import HomeLiga from './HomeLiga/HomeLiga';
import CreaLiga from './CrearLiga/CreaLiga';
import { Tab, Tabs } from '@material-ui/core';
import toast, { Toaster } from 'react-hot-toast';
import Containter from '../../Components/Container/Container';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useNavigate } from 'react-router'

const Styles = makeStyles({
    tableContainer: {
        display: 'flex',
        justifyContent: 'center',
        minWidth: "90%",
        borderStyle: "solid",
        borderWidth: 2,
        borderRadius: 10,
        height: 100,
    },
    noDataContainer: {
        border: 'solid 1px white',
        padding: '0.2em 1em 0.2em 1em',
        borderRadius: '5px',
        minWidth: '90%',
    },
    filtros: {
        margin: 10
    },

    noDataText: {
        textAlign: 'center',
    },
})

const Liga = (props) => {
    const navigate = useNavigate()
    const clases = Styles()
    const [ligaSelect, setLigaSelect] = useState({id:0})
    const [ligas, setLigas] = useState([])

    const [params, setParams] = useState({
        disciplina_id: "",
        sexo: 3,
        categoria_desde: 1,
        categoria_hasta: 14,
        edad_desde: 1,
        edad_hasta: 99,
        altura_desde: 10,
        altura_hasta: 250,
        peso_desde: 1,
        peso_hasta: 250,
        tipo_compe: 0
    })
    const [del, setDel] = useState(false)
    useEffect(() => {
     
        fetchShowLigas()
    }, [])




    const fetchPruebaGrupos = async (id) => {
        try {
              let cate=2;
              let dia=1;
              let liga=1;
            let res = await fetch(`${process.env.REACT_APP_BACKEND_LIGA}/CompeGrupos/${cate}&${dia}&${liga}`,
                {
                    method: "GET",
                    headers: { 'Content-type': 'application/json' },

                })
            let response = await res.json()
            if (response.code == 200) {
               
             
                console.log(response)

            } else   {
                console.log(response)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const fetchSelectLiga = async () => {
        try {

            let res = await fetch(`${process.env.REACT_APP_BACKEND_LIGA}/iniciarLiga/${ligaSelect.id}`, //activa la liga al seleccionarla
                {
                    method: "GET",
                    headers: { 'Content-type': 'application/json' },
                })
            let response = await res.json()
            if (response.code == 200) { 
                navigate('/Homeliga', { state: ligaSelect })               
            } else if (response.code == 400) {
                notify(0,response.mensaje)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const fetchShowLigas = async (id) => {
        try {

            let res = await fetch(`${process.env.REACT_APP_BACKEND_LIGA}/showLiga/0`,
                {
                    method: "GET",
                    headers: { 'Content-type': 'application/json' },

                })
            let response = await res.json()
            if (response.code == 200) {
               
                let activa = response.data.ligas.filter((x) => x.estado == 1 && x.finalizada ==0)
                 console.log("A",activa.length)
                if (activa.length > 0) {
                   
                    setLigas(activa)

                } else{
                    let activa = response.data.ligas.filter((x) => x.estado == 0 && x.finalizada ==0)
                  
                    setLigas(activa)

                }
                
                console.log("SELECTR ACTIV", activa)


            } else if (response.code == 400) {
                setLigas([])
            }
        } catch (error) {
            console.log(error)
        }
    }
 

    const notify = (x, nombre) => {
        if (x == 1) {
            toast.success(nombre + '.', { style: { background: 'lightgreen', color: "Black" } });
        } else {
            toast.error(nombre + '.', { style: { background: 'red', color: "white" } });
        }
    }
    const filter = (x) => {
        let nombre = x.target.name
        let valor = x.target.value
        switch (nombre) {
            case "disciplinas":
                setParams({ ...params, disciplina_id: valor })
                break;
            case "sexo":
                setParams({ ...params, sexo: valor })
                break;
            case "categoria_desde":
                setParams({ ...params, categoria_desde: valor })
                break;
            case "categoria_hasta":
                setParams({ ...params, categoria_hasta: valor })
                break;
            case "edad_desde":
                setParams({ ...params, edad_desde: valor })
                break;
            case "edad_hasta":
                setParams({ ...params, edad_hasta: valor })
                break;
            case "peso_desde":
                setParams({ ...params, peso_desde: valor })
                break;
            case "peso_hasta":
                setParams({ ...params, peso_hasta: valor })
                break;
            case "altura_desde":
                setParams({ ...params, altura_desde: valor })
                break;
            case "altura_hasta":
                setParams({ ...params, altura_hasta: valor })
                break;
            default:
                break;
        }

    }


    const handleChangeMultiple = (event) => {

        const value = event.target.value;
        console.log(value)
          let v={}
         ligas.map((x)=>{
              if (x.id==value){
           v={id:value,estado:x.estado}
              }
         })
       
        setLigaSelect(v);
    };

    const controlLigaActiva = () => {
        fetchSelectLiga() 
    }

    return (
        <Containter title={"LIGA "} more styles={{ width: "100%" }} >
            <Toaster position="center-center" />
            <div style={{ display: "flex", flexDirection: "row" }} >
               <Button onClick={() => { navigate('/Crealiga') }} style={{ background: "red" }}> CREAR NUEVA LIGA </Button>
            </div>
            <div style={{ display: "flex", flexDirection: "row", margin: 50 }} >
    
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Liga</InputLabel>
                    <Select
                        sx={{ background: "white" }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={ligaSelect.id}
                        label="Age"
                        onChange={(e) => handleChangeMultiple(e)}
                    >
                        <MenuItem value={0} key={0} disabled > Seleccione Competencia</MenuItem>

                        {ligas.map((x) => (
                            <MenuItem value={x.id} key={x.id} >  {"Liga " + x.id + " " + x.nombre}</MenuItem>
                        ))}
                    </Select>
                </FormControl>


                <Button disabled={ligaSelect == 0 && ligas.length<1 ? true : false} onClick={() => controlLigaActiva()} style={{ background: "green" }}> IR </Button>
            </div>
        </Containter>


    )
}

export default Liga