import { MenuItem, Select, TextField, Typography, Button, CircularProgress, IconButton } from '@material-ui/core'
import { Container, Card, CardContent, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { useEffect, useState, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Containter from '../../../Components/Container/Container';
import toast, { Toaster } from 'react-hot-toast';
import { useLocation } from 'react-router-dom';
import { QrReader } from 'react-qr-reader';
import RecuadroCam from './RecuadroCam';
import QRreader from './QRreader';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PersonOffRoundedIcon from '@mui/icons-material/PersonOffRounded';
import HowToRegRoundedIcon from '@mui/icons-material/HowToRegRounded';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { Icon } from '@mui/material';
const useStyles = makeStyles((theme) => ({
    conatiner: {
        marginTop: 10
    },
    title: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: '#3f51b5',
        color: '#fff',
        padding: 20
    },
    btnCancel: {
        marginTop: 10,
        marginBottom: 20,
        marginRight: "5%",
        marginleft: "5%",
        width: "40%",
        backgroundColor: "red"
    },
    btnAcept: {
        marginTop: 10,
        marginBottom: 20,
        marginLeft: "5%",
        marginRight: "5%",
        width: "40%",
        backgroundColor: "darkgreen"
    },
}));





const Acreditacion = (props) => {
    const location = useLocation();
    let puesto = location.state.puesto ? location.state.puesto : 0;
    const navigate = useNavigate()

    const [dniScanned, setdniScanned] = useState(null);

    const [dniManual, setDniManual] = useState(null);
    const [datos, setDatos] = useState({});
    const [dia, setDia] = useState(null);
    const [liga_id, setLiga_id] = useState(null);
    const [estadoAcreditacion, setEstadoAcreditacion] = useState(0);
    const [loading, setLoading] = useState(false)
    const classes = useStyles();

    useEffect(() => {
        fetchShowLigaActiva()

    }, [])


    const fetchShowLigaActiva = async () => {
        setLoading(true)
        try {
            let res = await fetch(`${process.env.REACT_APP_BACKEND_LIGA}/Acreditacion/showLigaActiva`,
                {
                    method: "get",
                    headers: { 'Content-type': 'application/json' },

                })
            let response = await res.json()
            if (response.code == 200) {
                setDia(response.dia);
                setLiga_id(response.liga_id);
                setLoading(false)




                //   notify(1, response.mensaje)

            } else {

                setLoading(false)

                notify(0, response.mensaje)
            }
        } catch (error) {
            console.log(error)
        }


    }

    const Scan = async (dni) => {
        setLoading(true)
        try {

            let data = { dni: dni }
            let res = await fetch(`${process.env.REACT_APP_BACKEND_LIGA}/Acreditacion/acreditacion`,
                {
                    method: "post",
                    headers: { 'Content-type': 'application/json' },
                    body: JSON.stringify(data)
                })
            let response = await res.json()
            if (response.code == 200) {
                setDatos({
                    ...datos, nombre: response.datos.nombre,
                    apellido: response.datos.apellido,
                    categoria: response.datos.categoria,
                    dni: response.datos.dni,
                    edad: response.datos.edad,
                    categoria_id: response.datos.categoria_id,
                    sexo: response.datos.sexo,
                    peso: response.datos.peso,
                    altura: response.datos.altura
                });
                setdniScanned(dni)
                setEstadoAcreditacion(1)
                setLoading(false)
                setDniManual(null)
                //   notify(1, response.mensaje)

            } else {

                setLoading(false)

                notify(0, response.mensaje)


            }
        } catch (error) {
            console.log(error)
        }


    }
    const URL_LENGTH = 31

    // const handleResult = (result, error) => {
    //     if (result) {
    //         const preDni = result?.text
    //         const dni = String(preDni).slice(URL_LENGTH, String(preDni).length)
    //         if (dni.length > 0) {
    //             console.log({ datos })
    //             console.log(dni) //TODO: despues de usarmme borrame forro

    //             Scan(dni)
    //         } else {
    //             notify(0, "LECTURA INCORRECTA, INTENTE NUEVAMENTE.")

    //         }

    //     } else {
    //         console.error(error) //TODO: despues de usarmme borrame  forro 
    //     }

    // }

    const notify = (x, nombre) => {
        if (x == 1) {
            toast.success(nombre + '.', { style: { background: 'lightgreen', color: "Black" } });
        } else {
            toast.error(nombre + '.', { style: { background: 'red', color: "white" } });
        }
    }

    const tamañoTexto = (texto) => {
        let size = texto.length
        let font = 10
        if (size <= 20) {
            font = 30;
        } else if (size >= 21 && size <= 30) {
            font = 20
        } else if (size >= 31 && size <= 40) {
            font = 15
        } else {
            font = 10
        }


        return font
    }
    const Cancelar = async () => {
        setLoading(true)
        try {

            let data = { dni: dniScanned }
            let res = await fetch(`${process.env.REACT_APP_BACKEND_LIGA}/Acreditacion/cancelAcreditacion`,
                {
                    method: "post",
                    headers: { 'Content-type': 'application/json' },
                    body: JSON.stringify(data)
                })
            let response = await res.json()
            if (response.code == 200) {
                setDatos({});

                setEstadoAcreditacion(0)
                setLoading(false)
            } else {
                setLoading(false)
                notify(0, response.mensaje)
            }
        } catch (error) {
            console.log(error)
        }

    }

    const Aceptar = () => {
        setdniScanned(null)
        setDatos({})
    }

    let nombrecompleto = datos.nombre ? datos.nombre + " " + datos.apellido : "- -"

    return (
        <div style={{ backgroundColor: "black", width: "100%", height: "88vh", padding: 0, margin: 0, display: "flex", flexDirection: "column" }} >
            {/* <div style={{ display: "flex", flexDirection: "row", height: "5px" }}> 
                <Typography style={{width:"100%",fontSize:30, textAlign:"center"}}  >{"FECHA  " + dia}</Typography>
            </div> */}
            <div style={{ width: '100%', height: "50vh" }}>

                {dniScanned === null ?
                    <div style={{ width: '100%' }}>
                        <QRreader dia={dia} dni={(dni) => Scan(dni)} />
                    </div>
                    :
                    <>
                        {estadoAcreditacion === 1 ?
                            <Icon style={{ width: "100%", height: "30vh" }}>
                                <HowToRegRoundedIcon style={{ fontSize: 150, color: "darkgreen" }} />
                                <Typography style={{ color: "darkgreen", fontWeight: "bold" }} >Competidor Acreditado!!</Typography>
                            </Icon>
                            :
                            <Icon style={{ width: "100%", height: "30vh" }}>
                                <PersonOffRoundedIcon style={{ fontSize: 150, color: "red" }} />
                                <Typography style={{ color: "red", fontWeight: "bold" }} >Acreditacion Cancelada</Typography>
                            </Icon>

                        }
                    </>
                }
            </div>

            {dniScanned === null ?
                <>
                    <Typography style={{ textAlign: "center", marginBottom: "10px", fontWeight: "bold" }}>SCANEE QR</Typography>

                   <Typography style={{ textAlign: "center", marginBottom: "10px", marginTop: "40px", fontWeight: "bold" }}>ACREDITACION MANUAL</Typography>
                    <TextField
                        id="outlined"
                        label="DNI"
                        type='number'
                        value={dniManual}
                        onChange={(x) => { setDniManual(x.target.value) }}
                        style={{ width: "80%", textJustify: "auto", marginLeft: "10%", textAlign: "center", alignItems: "center", marginBottom: "10px" }}> </TextField>

                    <IconButton
                        onClick={() => { Scan(dniManual) }}
                        style={{ width: "80%", marginLeft: "10%", background: "green", textAlign: "center", marginBottom: "10px", fontWeight: "bold" }}>
                        <SearchRoundedIcon/>
                    </IconButton>



                </>
                :
                <>
                    <div style={{ width: '100%' }}>
                        <Typography variant='h1'>{datos?.liga_name}</Typography>
                        <Typography style={{ textAlign: "center", marginBottom: "10px", fontSize: tamañoTexto(nombrecompleto), fontWeight: "bold" }}>{nombrecompleto}</Typography>
                        <Typography variant='h6' style={{ textAlign: "center", marginBottom: "10px" }}>  {datos?.dni}</Typography>
                        <Typography variant='h6' style={{ textAlign: "center", marginBottom: "10px" }}  > {datos?.categoria}</Typography>
                        <Typography variant='h6' style={{ textAlign: "center", marginBottom: "10px" }}  > {datos?.edad} años.</Typography>
                        <Typography variant='h6' style={{ textAlign: "center", marginBottom: "10px" }}  > {datos?.altura} cm.</Typography>
                        <Typography variant='h6' style={{ textAlign: "center", marginBottom: "10px" }}  > {datos?.peso} kg.</Typography>
                    </div>

                    <div>
                        <Button onClick={() => { Cancelar() }} className={classes.btnCancel}>CANCELAR</Button>
                        <Button onClick={() => { Aceptar() }} className={classes.btnAcept}>ACEPTAR</Button>
                    </div>
                </>
            }

            <Toaster position="top-center" />
        </div>
    )
}

export default Acreditacion;
