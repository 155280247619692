import { Button, IconButton, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles';
import React, { useState, useEffect } from 'react'
import CoPresentIcon from '@mui/icons-material/CoPresent';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import { useLocation, useNavigate } from "react-router-dom"
import DataCategoty from './Encabezado/DataCategory';
import Containter from '../../../Components/Container/Container';

const Styles = makeStyles({
    tableContainer: {
        display: 'flex',
        justifyContent: 'center',
        minWidth: "90%",
        borderStyle: "solid",
        borderWidth: 2,
        borderRadius: 10,
        height: 100,
    },
    recuadro: {
        width: "60%",
        display: "flex",
        flexDirection: "row",
        borderWidth: 0.5,
        borderStyle: "solid",
         marginLeft: "20%",
        "@media (max-width: 910px)": {
          //  marginLeft: "10px",
            width: "100%",
         

        }
    }


})

const AreaAsistenciaCompetidor = (props) => {
    const clases = Styles()

    const [llaveAsistencia, setLLaveAsistencia] = useState([])
    const [area, setArea] = useState(0);
    const [tipo, setTipo] = useState(0);
    const [datosCategoria, setDatosCategoria] = useState({})
    const navigate = useNavigate()
    const location = useLocation();
    const [actualizar, setActualizar] = useState(0);
    var categoria_id = 0;
    var dia = 0
    var liga_id = 0
    var infoCategorias = []
    if (location.state) {
        // datos = location.state.llave
        categoria_id = location.state.categoria.id;
        dia = location.state.categoria.dia;
        liga_id = location.state.categoria.liga_id;

    }

    useEffect(() => {
        fetchShowLLave(categoria_id, dia, liga_id)
       

    }, [actualizar,categoria_id])
    const obtenDatosCategoria = (c) => {
        let info = infoCategorias.filter((x) => x.id == c)

        setDatosCategoria(info[0])
        return info[0]
    }

    const fetchShowLLave = async (c, d, l) => {
        try {
            let res = await fetch(`${process.env.REACT_APP_BACKEND_LIGA}/showAsistenciaLLaves/${c}&${d}&${l}`,
                {
                    method: "get",
                    headers: { 'Content-type': 'application/json' },
                    // body: JSON.stringify(params)
                })
            let response = await res.json()
            if (response.code == 200) {
                setLLaveAsistencia(response.data.llave)
            } else if (response.code == 400) {
                console.log("error")
            }
        } catch (error) {
            console.log(error)
        }
    }
    const fetchAsistencia = async (c) => {
        try { //update

            let data = { ...c, presente: c.presente == 1 ? 0 : 1 };

            let res = await fetch(`${process.env.REACT_APP_BACKEND_LIGA}/asistenciaCompetidores`,
                {
                    method: "POST",
                    headers: { 'Content-type': 'application/json' },
                    body: JSON.stringify(data)

                })

            let response = await res.json()

            if (response.code == 200) {
                setLLaveAsistencia(response.data.llave)
                setTipo(0)

            } else {
                console.log("no200", response)
            }


        } catch (error) {
            console.log(error)
        }

    }
    const fetchComenzar = async (c) => {
        try {

            let data = { datosLLave: c, dia: dia, liga_id: liga_id, categoria_id: c[0].categoria_id };
            console.log("DATA ASISTENCIA ", data)
            let res = await fetch(`${process.env.REACT_APP_BACKEND_LIGA}/comenzarEncuentro`,
                {
                    method: "POST",
                    headers: { 'Content-type': 'application/json' },
                    body: JSON.stringify(data)
                })

            let response = await res.json()
            if (response.code == 200) {
                navigate("/areas/llaveGeneral", { state: { tipo: response.data.tipo_compe, categoria: location.state.categoria, llave: response.data } })
            } else {
                console.log("no200", response)
            }


        } catch (error) {
            console.log(error)
        }

    }
    const llavePresentes = () => {

        let presentes = llaveAsistencia.filter((x) => x.presente > 0)
        fetchComenzar(presentes)

    }

    return (
        <Containter more>
            <div style={{ width: "100%" }}   >
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                    <DataCategoty titulo={"ASISTENCIA COMPETIDORES"} data={location.state.categoria} />
                    <div style={{ display: "flex", flexDirection: "row", width: "100%", height: "50px", marginTop: "20px", marginBottom: "10px", alignContent: "center" }}>
                        <Typography>{datosCategoria?.disciplina_name}</Typography>
                        <Button
                            onClick={() => navigate("/areas", { state: { categoria: 0 } })}
                            style={{ fontSize: 20, backgroundColor: "red", width: "20%", height: "50px",marginRight:"10%"  }}
                        > {"<<  "}</Button>
                        <Button
                            onClick={() => setActualizar(!actualizar)}
                            style={{ fontSize: 10, backgroundColor: "blue", width: "20%", height: "50px",marginRight:"10%",marginLeft:"10%" }}
                        >ACTUALIZAR</Button>
                        <Button
                            onClick={() => llavePresentes()}
                            style={{ fontSize: 10, backgroundColor: "darkgreen", width: "20%", height: "50px", marginLeft:"10%"}}
                        >COMENZAR</Button>

                    </div>
                </div>

                {llaveAsistencia?.length > 0 ?
                    <>
                        {llaveAsistencia?.map((x) => {
                            return (
                                <div className={clases.recuadro}>
                                    {x.presente == 1 ? <IconButton onClick={() => fetchAsistencia(x)} style={{ width: "30%", color: "lightgreen" }}>
                                        <CoPresentIcon style={{ fontSize: 30 }} />
                                    </IconButton>
                                        : <IconButton onClick={() => fetchAsistencia(x)} style={{ width: "30%", color: "firebrick" }}>
                                            <CancelPresentationIcon style={{ fontSize: 30 }} />
                                        </IconButton>
                                    }
                                    <Typography style={{ width: "40%", alignContent: "center" }} >{x.nombre}</Typography>
                                    <Typography style={{ width: "30%", alignContent: "center" }} >{x.categoria}</Typography>
                                </div>
                            )
                        })}
                    </>
                    : undefined
                }
            </div>
        </Containter>
    )
}
export default AreaAsistenciaCompetidor