
import jsPDF from 'jspdf'

import React, { useState } from 'react';
import logo from "./../../../Assets/Imagenes/Logo_SUPERCOPA.png"
import QRCode from 'qrcode'
import { useEffect } from 'react';
import moment from 'moment/moment';

const PDFInscriptos = (props) => {



  const { alumnos, listo } = props;
// let  alumnos=[ // provisorio

 

// {  "id": 3,
//     "nombre": "thiago",
//     "apellido": "todoroff",
//     "dni": 49436130,
//     "sexo": "2",
//     "categoria": "AZUL",
//     "categoria_id": 7,
//     "instructor": "MARTIN M.",
//     "escuela": null,
//     "edad": 15,
//     "altura": "57",
//     "peso": "171",
//     "instructor_id": 2,
//     "anio": 2024
// },
// { "id": 4,
//     "nombre": "Pablo Luciano",
//     "apellido": "Anguita",
//     "dni": 26201076,
//     "sexo": "2",
//     "categoria": "AZUL PUNTA ROJA",
//     "categoria_id": 8,
//     "instructor": "NESTOR A.",
//     "escuela": null,
//     "edad": 46,
//     "altura": "82",
//     "peso": "178",
//     "instructor_id": 1,
//     "anio": 2024
// },
// { "id": 5,
//     "nombre": "CHRISTIAN GABRIEL",
//     "apellido": "BUTKUS",
//     "dni": 43571103,
//     "sexo": "2",
//     "categoria": "SEGUNDO DAN",
//     "categoria_id": 12,
//     "instructor": "NESTOR A.",
//     "escuela": null,
//     "edad": 23,
//     "altura": "60",
//     "peso": "175",
//     "instructor_id": 1,
//     "anio": 2024
// },
// {  "id": 6,
//     "nombre": "Guillermo",
//     "apellido": "Ochoa",
//     "dni": 95938584,
//     "sexo": "2",
//     "categoria": "VERDE",
//     "categoria_id": 5,
//     "instructor": "MARTIN M.",
//     "escuela": null,
//     "edad": 8,
//     "altura": "35",
//     "peso": "136",
//     "instructor_id": 2,
//     "anio": 2024
// },
// {  "id": 7,
//     "nombre": "Catalina",
//     "apellido": "Chimenti",
//     "dni": 53333946,
//     "sexo": "1",
//     "categoria": "VERDE",
//     "categoria_id": 5,
//     "instructor": "MARTIN M.",
//     "escuela": null,
//     "edad": 11,
//     "altura": "58",
//     "peso": "158",
//     "instructor_id": 2,
//     "anio": 2024
// },
// { "id": 8,
//     "nombre": "Nina",
//     "apellido": "Bevcar",
//     "dni": 55395007,
//     "sexo": "1",
//     "categoria": "AMARILLO",
//     "categoria_id": 3,
//     "instructor": "MARTIN M.",
//     "escuela": null,
//     "edad": 8,
//     "altura": "36",
//     "peso": "133",
//     "instructor_id": 2,
//     "anio": 2024
// },
// {  "id": 9,
//     "nombre": "NAZARENA SOL",
//     "apellido": "FARHUD SANCHEZ",
//     "dni": 46877797,
//     "sexo": "1",
//     "categoria": "PRIMER DAN",
//     "categoria_id": 11,
//     "instructor": "NESTOR A.",
//     "escuela": null,
//     "edad": 19,
//     "altura": "53",
//     "peso": "160",
//     "instructor_id": 1,
//     "anio": 2024
// },
// { "id": 10,
//     "nombre": "Santiago Ivan",
//     "apellido": "Kang",
//     "dni": 53856323,
//     "sexo": "2",
//     "categoria": "VERDE",
//     "categoria_id": 5,
//     "instructor": "NESTOR A.",
//     "escuela": null,
//     "edad": 10,
//     "altura": "24",
//     "peso": "133",
//     "instructor_id": 1,
//     "anio": 2024
// },
// {  "id": 11,
//     "nombre": "Tomas Carlos",
//     "apellido": "Fernandez Perez",
//     "dni": 56070980,
//     "sexo": "2",
//     "categoria": "BLANCO PUNTA AMARILLA",
//     "categoria_id": 2,
//     "instructor": "NESTOR A.",
//     "escuela": null,
//     "edad": 7,
//     "altura": "35",
//     "peso": "133",
//     "instructor_id": 1,
//     "anio": 2024
// },
// { "id": 12,
//     "nombre": "Santiago",
//     "apellido": "Lavia",
//     "dni": 54099776,
//     "sexo": "2",
//     "categoria": "AZUL",
//     "categoria_id": 7,
//     "instructor": "CHRISTIAN GABRIEL",
//     "escuela": null,
//     "edad": 10,
//     "altura": "35",
//     "peso": "143",
//     "instructor_id": 471,
//     "anio": 2024
// },
// { "id": 13,
//     "nombre": "Mateo Nicolas",
//     "apellido": "Lavia",
//     "dni": 56578300,
//     "sexo": "2",
//     "categoria": "BLANCO",
//     "categoria_id": 1,
//     "instructor": "CHRISTIAN GABRIEL",
//     "escuela": null,
//     "edad": 6,
//     "altura": "21",
//     "peso": "118",
//     "instructor_id": 471,
//     "anio": 2024
// },
// {  "id": 14,
//     "nombre": "Catalina",
//     "apellido": "Barrios Garcia Huidobro",
//     "dni": 50434268,
//     "sexo": "1",
//     "categoria": "AMARILLO PUNTA VERDE",
//     "categoria_id": 4,
//     "instructor": "NESTOR A.",
//     "escuela": null,
//     "edad": 14,
//     "altura": "65",
//     "peso": "162",
//     "instructor_id": 1,
//     "anio": 2024
// },
// { "id": 15,
//     "nombre": "Bianca",
//     "apellido": "Marini Mazzucchelli",
//     "dni": 53717717,
//     "sexo": "1",
//     "categoria": "VERDE PUNTA AZUL",
//     "categoria_id": 6,
//     "instructor": "NESTOR A.",
//     "escuela": null,
//     "edad": 10,
//     "altura": "39",
//     "peso": "153",
//     "instructor_id": 1,
//     "anio": 2024
// }, {
//   "id": 16,
//   "nombre": "Malena",
//   "apellido": "Stanicio",
//   "dni": 54095354,
//   "sexo": "1",
//   "categoria": "VERDE PUNTA AZUL",
//   "categoria_id": 6,
//   "instructor": "MARTIN M.",
//   "escuela": null,
//   "edad": 10,
//   "altura": "36",
//   "peso": "140",
//   "instructor_id": 2,
//   "anio": 2024
// },
// {
//   "id": 17,
//   "nombre": "Luz Anahi",
//   "apellido": "Yañez",
//   "dni": 52861896,
//   "sexo": "1",
//   "categoria": "VERDE PUNTA AZUL",
//   "categoria_id": 6,
//   "instructor": "CHRISTIAN GABRIEL",
//   "escuela": null,
//   "edad": 11,
//   "altura": "50",
//   "peso": "152",
//   "instructor_id": 471,
//   "anio": 2024
// },
// {
//   "id": 18,
//   "nombre": "Naiara Victoria",
//   "apellido": "Ayala",
//   "dni": 53085679,
//   "sexo": "1",
//   "categoria": "VERDE",
//   "categoria_id": 5,
//   "instructor": "MARTIN M.",
//   "escuela": null,
//   "edad": 11,
//   "altura": "70",
//   "peso": "160",
//   "instructor_id": 2,
//   "anio": 2024
// },
// {
//   "id": 19,
//   "nombre": "MARIA LUJAN",
//   "apellido": "BARCAT DI PASQUO",
//   "dni": 50657483,
//   "sexo": "1",
//   "categoria": "PRIMER DAN",
//   "categoria_id": 11,
//   "instructor": "SANTIAGO EZEQUIEL",
//   "escuela": null,
//   "edad": 13,
//   "altura": "60",
//   "peso": "160",
//   "instructor_id": 7,
//   "anio": 2024
// },
// {
//   "id": 20,
//   "nombre": "Sofía Elena",
//   "apellido": "Liberti",
//   "dni": 52952055,
//   "sexo": "1",
//   "categoria": "ROJO",
//   "categoria_id": 9,
//   "instructor": "SOFIA BELEN",
//   "escuela": null,
//   "edad": 11,
//   "altura": "43",
//   "peso": "143",
//   "instructor_id": 270,
//   "anio": 2024
// },
// {
//   "id": 21,
//   "nombre": "TOM",
//   "apellido": "CARINA",
//   "dni": 47392145,
//   "sexo": "1",
//   "categoria": "AZUL PUNTA ROJA",
//   "categoria_id": 8,
//   "instructor": "MARTIN M.",
//   "escuela": null,
//   "edad": 18,
//   "altura": "58",
//   "peso": "169",
//   "instructor_id": 2,
//   "anio": 2024
// },
// {
//   "id": 22,
//   "nombre": "Catalina Eva",
//   "apellido": "Collado Flores",
//   "dni": 52187373,
//   "sexo": "1",
//   "categoria": "BLANCO",
//   "categoria_id": 1,
//   "instructor": "MARTIN M.",
//   "escuela": null,
//   "edad": 12,
//   "altura": "36",
//   "peso": "144",
//   "instructor_id": 2,
//   "anio": 2024
// },
// {
//   "id": 23,
//   "nombre": "Camila Luz",
//   "apellido": "Campos Limardo",
//   "dni": 53472922,
//   "sexo": "1",
//   "categoria": "VERDE",
//   "categoria_id": 5,
//   "instructor": "NESTOR A.",
//   "escuela": null,
//   "edad": 10,
//   "altura": "31",
//   "peso": "140",
//   "instructor_id": 1,
//   "anio": 2024
// },
// {
//   "id": 24,
//   "nombre": "Emilia Uma",
//   "apellido": "Campos Limardo",
//   "dni": 55390850,
//   "sexo": "1",
//   "categoria": "VERDE PUNTA AZUL",
//   "categoria_id": 6,
//   "instructor": "NESTOR A.",
//   "escuela": null,
//   "edad": 8,
//   "altura": "23",
//   "peso": "126",
//   "instructor_id": 1,
//   "anio": 2024
// },
// {
//   "id": 25,
//   "nombre": "Santiago",
//   "apellido": "Burgue",
//   "dni": 53722525,
//   "sexo": "2",
//   "categoria": "BLANCO",
//   "categoria_id": 1,
//   "instructor": "NESTOR A.",
//   "escuela": null,
//   "edad": 10,
//   "altura": "36",
//   "peso": "144",
//   "instructor_id": 1,
//   "anio": 2024
// },
// {
//   "id": 26,
//   "nombre": "Alma",
//   "apellido": "Gonzalez",
//   "dni": 55161861,
//   "sexo": "1",
//   "categoria": "AMARILLO PUNTA VERDE",
//   "categoria_id": 4,
//   "instructor": "MILAGROS",
//   "escuela": null,
//   "edad": 8,
//   "altura": "32",
//   "peso": "141",
//   "instructor_id": 410,
//   "anio": 2024
// },
// {
//   "id": 27,
//   "nombre": "Benjamin",
//   "apellido": "Berberian",
//   "dni": 53418001,
//   "sexo": "2",
//   "categoria": "VERDE",
//   "categoria_id": 5,
//   "instructor": "NESTOR A.",
//   "escuela": null,
//   "edad": 11,
//   "altura": "36",
//   "peso": "150",
//   "instructor_id": 1,
//   "anio": 2024
// },
// {
//   "id": 28,
//   "nombre": "Gema",
//   "apellido": "Iacono Bauleo",
//   "dni": 54455636,
//   "sexo": "1",
//   "categoria": "VERDE PUNTA AZUL",
//   "categoria_id": 6,
//   "instructor": "NESTOR A.",
//   "escuela": null,
//   "edad": 9,
//   "altura": "35",
//   "peso": "130",
//   "instructor_id": 1,
//   "anio": 2024
// },
// {
//   "id": 29,
//   "nombre": "Ramiro",
//   "apellido": "Di Lorenzo",
//   "dni": 54790843,
//   "sexo": "2",
//   "categoria": "VERDE",
//   "categoria_id": 5,
//   "instructor": "MILAGROS",
//   "escuela": null,
//   "edad": 9,
//   "altura": "35",
//   "peso": "139",
//   "instructor_id": 410,
//   "anio": 2024
// },
// {
//   "id": 30,
//   "nombre": "Olivia",
//   "apellido": "Mendieta ponton",
//   "dni": 55764564,
//   "sexo": "1",
//   "categoria": "BLANCO",
//   "categoria_id": 1,
//   "instructor": "MARTIN M.",
//   "escuela": null,
//   "edad": 7,
//   "altura": "34",
//   "peso": "132",
//   "instructor_id": 2,
//   "anio": 2024
// },    {
//   "id": 31,
//   "nombre": "Nina",
//   "apellido": "Orlando Lamorte",
//   "dni": 54696910,
//   "sexo": "1",
//   "categoria": "VERDE",
//   "categoria_id": 5,
//   "instructor": "MARTIN M.",
//   "escuela": null,
//   "edad": 9,
//   "altura": "23",
//   "peso": "123",
//   "instructor_id": 2,
//   "anio": 2024
// },
// {
//   "id": 32,
//   "nombre": "Octavio Tomas",
//   "apellido": "Brey Gallar",
//   "dni": 53854778,
//   "sexo": "2",
//   "categoria": "VERDE PUNTA AZUL",
//   "categoria_id": 6,
//   "instructor": "CHRISTIAN GABRIEL",
//   "escuela": null,
//   "edad": 10,
//   "altura": "31",
//   "peso": "138",
//   "instructor_id": 471,
//   "anio": 2024
// },
// {
//   "id": 33,
//   "nombre": "Camila",
//   "apellido": "Alonso Heavey",
//   "dni": 46988802,
//   "sexo": "1",
//   "categoria": "BLANCO",
//   "categoria_id": 1,
//   "instructor": "MARTIN M.",
//   "escuela": null,
//   "edad": 18,
//   "altura": "66",
//   "peso": "171",
//   "instructor_id": 2,
//   "anio": 2024
// },
// {
//   "id": 34,
//   "nombre": "Zoe",
//   "apellido": "Miano",
//   "dni": 55067042,
//   "sexo": "1",
//   "categoria": "VERDE",
//   "categoria_id": 5,
//   "instructor": "NESTOR A.",
//   "escuela": null,
//   "edad": 9,
//   "altura": "33",
//   "peso": "130",
//   "instructor_id": 1,
//   "anio": 2024
// },
// {
//   "id": 35,
//   "nombre": "Josefina",
//   "apellido": "Arnay",
//   "dni": 53415283,
//   "sexo": "1",
//   "categoria": "AZUL PUNTA ROJA",
//   "categoria_id": 8,
//   "instructor": "MARTIN M.",
//   "escuela": null,
//   "edad": 11,
//   "altura": "40",
//   "peso": "140",
//   "instructor_id": 2,
//   "anio": 2024
// },
// {
//   "id": 36,
//   "nombre": "Athenea",
//   "apellido": "Rodríguez",
//   "dni": 56996750,
//   "sexo": "1",
//   "categoria": "BLANCO",
//   "categoria_id": 1,
//   "instructor": "MARTIN M.",
//   "escuela": null,
//   "edad": 6,
//   "altura": "19",
//   "peso": "120",
//   "instructor_id": 2,
//   "anio": 2024
// },
// {
//   "id": 37,
//   "nombre": "Juan Sebastian",
//   "apellido": "Duran",
//   "dni": 54457059,
//   "sexo": "2",
//   "categoria": "AMARILLO",
//   "categoria_id": 3,
//   "instructor": "FERNANDO SEBASTIAN",
//   "escuela": null,
//   "edad": 9,
//   "altura": "30",
//   "peso": "130",
//   "instructor_id": 246,
//   "anio": 2024
// },
// {
//   "id": 38,
//   "nombre": "Cristian Alexander",
//   "apellido": "Bravo Cardozo",
//   "dni": 56942072,
//   "sexo": "2",
//   "categoria": "BLANCO",
//   "categoria_id": 1,
//   "instructor": "MARTIN M.",
//   "escuela": null,
//   "edad": 6,
//   "altura": "26.9",
//   "peso": "115",
//   "instructor_id": 2,
//   "anio": 2024
// },
// {
//   "id": 39,
//   "nombre": "Mateo",
//   "apellido": "Quiroga",
//   "dni": 52853637,
//   "sexo": "2",
//   "categoria": "VERDE PUNTA AZUL",
//   "categoria_id": 6,
//   "instructor": "FERNANDO SEBASTIAN",
//   "escuela": null,
//   "edad": 11,
//   "altura": "40",
//   "peso": "139",
//   "instructor_id": 246,
//   "anio": 2024
// },
// {
//   "id": 40,
//   "nombre": "Micaela",
//   "apellido": "Kovach Bonelli",
//   "dni": 55067193,
//   "sexo": "1",
//   "categoria": "AMARILLO PUNTA VERDE",
//   "categoria_id": 4,
//   "instructor": "CHRISTIAN GABRIEL",
//   "escuela": null,
//   "edad": 9,
//   "altura": "43",
//   "peso": "147",
//   "instructor_id": 471,
//   "anio": 2024
// },
// {
//   "id": 41,
//   "nombre": "Giovanna",
//   "apellido": "Russo",
//   "dni": 53330055,
//   "sexo": "1",
//   "categoria": "VERDE PUNTA AZUL",
//   "categoria_id": 6,
//   "instructor": "NESTOR A.",
//   "escuela": null,
//   "edad": 11,
//   "altura": "43",
//   "peso": "144",
//   "instructor_id": 1,
//   "anio": 2024
// },
// {
//   "id": 42,
//   "nombre": "Isabella  martina",
//   "apellido": "Gallego",
//   "dni": 52960531,
//   "sexo": "1",
//   "categoria": "BLANCO PUNTA AMARILLA",
//   "categoria_id": 2,
//   "instructor": "MARTIN M.",
//   "escuela": null,
//   "edad": 11,
//   "altura": "34.5",
//   "peso": "141",
//   "instructor_id": 2,
//   "anio": 2024
// },
// {
//   "id": 43,
//   "nombre": "Lola",
//   "apellido": "Barral",
//   "dni": 53852291,
//   "sexo": "2",
//   "categoria": "VERDE PUNTA AZUL",
//   "categoria_id": 6,
//   "instructor": "CHRISTIAN GABRIEL",
//   "escuela": null,
//   "edad": 10,
//   "altura": "32",
//   "peso": "140",
//   "instructor_id": 471,
//   "anio": 2024
// },
// {
//   "id": 44,
//   "nombre": "Valentin",
//   "apellido": "Decurnex Bloise",
//   "dni": 52859889,
//   "sexo": "2",
//   "categoria": "BLANCO PUNTA AMARILLA",
//   "categoria_id": 2,
//   "instructor": "MARTIN M.",
//   "escuela": null,
//   "edad": 11,
//   "altura": "50",
//   "peso": "150",
//   "instructor_id": 2,
//   "anio": 2024
// },
// {
//   "id": 45,
//   "nombre": "Joaquín",
//   "apellido": "Rodríguez",
//   "dni": 54186250,
//   "sexo": "2",
//   "categoria": "AMARILLO PUNTA VERDE",
//   "categoria_id": 4,
//   "instructor": "BLAS R.",
//   "escuela": null,
//   "edad": 10,
//   "altura": "47.6",
//   "peso": "160",
//   "instructor_id": 3,
//   "anio": 2024
// }, {
//   "id": 46,
//   "nombre": "Lucio",
//   "apellido": "Alves",
//   "dni": 53173061,
//   "sexo": "2",
//   "categoria": "PRIMER DAN",
//   "categoria_id": 11,
//   "instructor": "SOFIA BELEN",
//   "escuela": null,
//   "edad": 11,
//   "altura": "31",
//   "peso": "140",
//   "instructor_id": 270,
//   "anio": 2024
// },
// {
//   "id": 47,
//   "nombre": "Luca",
//   "apellido": "Zucchi Tarantino",
//   "dni": 55001951,
//   "sexo": "2",
//   "categoria": "VERDE PUNTA AZUL",
//   "categoria_id": 6,
//   "instructor": "MARTIN M.",
//   "escuela": null,
//   "edad": 9,
//   "altura": "31",
//   "peso": "136",
//   "instructor_id": 2,
//   "anio": 2024
// },
// {
//   "id": 48,
//   "nombre": "luciano",
//   "apellido": "koval",
//   "dni": 56175070,
//   "sexo": "2",
//   "categoria": "BLANCO PUNTA AMARILLA",
//   "categoria_id": 2,
//   "instructor": "NESTOR A.",
//   "escuela": null,
//   "edad": 7,
//   "altura": "29",
//   "peso": "129",
//   "instructor_id": 1,
//   "anio": 2024
// },
// {
//   "id": 49,
//   "nombre": "Julian",
//   "apellido": "Daiello",
//   "dni": 37823015,
//   "sexo": "2",
//   "categoria": "AMARILLO PUNTA VERDE",
//   "categoria_id": 4,
//   "instructor": "MARTIN M.",
//   "escuela": null,
//   "edad": 31,
//   "altura": "100",
//   "peso": "185",
//   "instructor_id": 2,
//   "anio": 2024
// },
// {
//   "id": 50,
//   "nombre": "LUCA",
//   "apellido": "STANIZZO",
//   "dni": 47974690,
//   "sexo": "2",
//   "categoria": "PRIMER DAN",
//   "categoria_id": 11,
//   "instructor": "MARTIN M.",
//   "escuela": null,
//   "edad": 18,
//   "altura": "72",
//   "peso": "176",
//   "instructor_id": 2,
//   "anio": 2024
// },
// {
//   "id": 51,
//   "nombre": "Isabella",
//   "apellido": "Tomán Guariglia",
//   "dni": 56072017,
//   "sexo": "1",
//   "categoria": "AMARILLO",
//   "categoria_id": 3,
//   "instructor": "MARTIN M.",
//   "escuela": null,
//   "edad": 7,
//   "altura": "24",
//   "peso": "121",
//   "instructor_id": 2,
//   "anio": 2024
// },
// {
//   "id": 52,
//   "nombre": "Benito",
//   "apellido": "Miñano",
//   "dni": 53648688,
//   "sexo": "2",
//   "categoria": "BLANCO PUNTA AMARILLA",
//   "categoria_id": 2,
//   "instructor": "MARTIN M.",
//   "escuela": null,
//   "edad": 10,
//   "altura": "34",
//   "peso": "150",
//   "instructor_id": 2,
//   "anio": 2024
// },
// {
//   "id": 53,
//   "nombre": "Bautista",
//   "apellido": "Monteiro",
//   "dni": 53470035,
//   "sexo": "2",
//   "categoria": "AZUL",
//   "categoria_id": 7,
//   "instructor": "CHRISTIAN GABRIEL",
//   "escuela": null,
//   "edad": 9,
//   "altura": "28",
//   "peso": "140",
//   "instructor_id": 471,
//   "anio": 2024
// },
// {
//   "id": 54,
//   "nombre": "Lisandro",
//   "apellido": "Benitez",
//   "dni": 51430056,
//   "sexo": "2",
//   "categoria": "VERDE PUNTA AZUL",
//   "categoria_id": 6,
//   "instructor": "MARTIN M.",
//   "escuela": null,
//   "edad": 13,
//   "altura": "58",
//   "peso": "159",
//   "instructor_id": 2,
//   "anio": 2024
// },
// {
//   "id": 55,
//   "nombre": "Fernanda",
//   "apellido": "Besansón",
//   "dni": 38389910,
//   "sexo": "1",
//   "categoria": "PRIMER DAN",
//   "categoria_id": 11,
//   "instructor": "NESTOR A.",
//   "escuela": null,
//   "edad": 30,
//   "altura": "51",
//   "peso": "159",
//   "instructor_id": 1,
//   "anio": 2024
// },
// {
//   "id": 56,
//   "nombre": "Máximo",
//   "apellido": "Vega",
//   "dni": 52445638,
//   "sexo": "2",
//   "categoria": "VERDE PUNTA AZUL",
//   "categoria_id": 6,
//   "instructor": "CHRISTIAN GABRIEL",
//   "escuela": null,
//   "edad": 12,
//   "altura": "55",
//   "peso": "151",
//   "instructor_id": 471,
//   "anio": 2024
// },
// {
//   "id": 57,
//   "nombre": "Felipe",
//   "apellido": "Springer Rappallini",
//   "dni": 52702560,
//   "sexo": "2",
//   "categoria": "PRIMER DAN",
//   "categoria_id": 11,
//   "instructor": "SOFIA BELEN",
//   "escuela": null,
//   "edad": 11,
//   "altura": "33",
//   "peso": "141",
//   "instructor_id": 270,
//   "anio": 2024
// },
// {
//   "id": 58,
//   "nombre": "Pedro",
//   "apellido": "Springer Rappallini",
//   "dni": 54455713,
//   "sexo": "2",
//   "categoria": "ROJO",
//   "categoria_id": 9,
//   "instructor": "SOFIA BELEN",
//   "escuela": null,
//   "edad": 9,
//   "altura": "32",
//   "peso": "134",
//   "instructor_id": 270,
//   "anio": 2024
// },
// {
//   "id": 59,
//   "nombre": "Matilda",
//   "apellido": "Barranca",
//   "dni": 53852822,
//   "sexo": "1",
//   "categoria": "AMARILLO PUNTA VERDE",
//   "categoria_id": 4,
//   "instructor": "MARTIN M.",
//   "escuela": null,
//   "edad": 10,
//   "altura": "30",
//   "peso": "140",
//   "instructor_id": 2,
//   "anio": 2024
// },
// {
//   "id": 60,
//   "nombre": "Ramiro",
//   "apellido": "Flecha",
//   "dni": 54696071,
//   "sexo": "2",
//   "categoria": "AMARILLO PUNTA VERDE",
//   "categoria_id": 4,
//   "instructor": "FERNANDO SEBASTIAN",
//   "escuela": null,
//   "edad": 9,
//   "altura": "28",
//   "peso": "132",
//   "instructor_id": 246,
//   "anio": 2024
// },    {
//   "id": 61,
//   "nombre": "Dante benjamin",
//   "apellido": "Romano",
//   "dni": 48956608,
//   "sexo": "2",
//   "categoria": "PRIMER DAN",
//   "categoria_id": 11,
//   "instructor": "MARTIN M.",
//   "escuela": null,
//   "edad": 16,
//   "altura": "69",
//   "peso": "182",
//   "instructor_id": 2,
//   "anio": 2024
// },
// {
//   "id": 62,
//   "nombre": "Ciro Gabriel",
//   "apellido": "Paleta",
//   "dni": 52611303,
//   "sexo": "2",
//   "categoria": "VERDE PUNTA AZUL",
//   "categoria_id": 6,
//   "instructor": "MARTIN M.",
//   "escuela": null,
//   "edad": 12,
//   "altura": "32",
//   "peso": "140",
//   "instructor_id": 2,
//   "anio": 2024
// },
// {
//   "id": 63,
//   "nombre": "Bautista Daniel",
//   "apellido": "Paleta",
//   "dni": 53993080,
//   "sexo": "2",
//   "categoria": "VERDE PUNTA AZUL",
//   "categoria_id": 6,
//   "instructor": "MARTIN M.",
//   "escuela": null,
//   "edad": 10,
//   "altura": "34",
//   "peso": "137",
//   "instructor_id": 2,
//   "anio": 2024
// },
// {
//   "id": 64,
//   "nombre": "Bautista",
//   "apellido": "Tinganelli",
//   "dni": 55161224,
//   "sexo": "2",
//   "categoria": "AMARILLO PUNTA VERDE",
//   "categoria_id": 4,
//   "instructor": "MARTIN M.",
//   "escuela": null,
//   "edad": 8,
//   "altura": "24",
//   "peso": "131",
//   "instructor_id": 2,
//   "anio": 2024
// },
// {
//   "id": 65,
//   "nombre": "Ayelén",
//   "apellido": "Gasparian",
//   "dni": 53720423,
//   "sexo": "1",
//   "categoria": "VERDE",
//   "categoria_id": 5,
//   "instructor": "MARTIN M.",
//   "escuela": null,
//   "edad": 10,
//   "altura": "30",
//   "peso": "135",
//   "instructor_id": 2,
//   "anio": 2024
// },
// {
//   "id": 66,
//   "nombre": "Santiago",
//   "apellido": "Melendez Peruzzi",
//   "dni": 53856876,
//   "sexo": "2",
//   "categoria": "AMARILLO",
//   "categoria_id": 3,
//   "instructor": "NESTOR A.",
//   "escuela": null,
//   "edad": 10,
//   "altura": "35",
//   "peso": "135",
//   "instructor_id": 1,
//   "anio": 2024
// },
// {
//   "id": 67,
//   "nombre": "Juan Bautista",
//   "apellido": "Gentile",
//   "dni": 52698195,
//   "sexo": "2",
//   "categoria": "ROJO PUNTA NEGRA",
//   "categoria_id": 10,
//   "instructor": "SOFIA BELEN",
//   "escuela": null,
//   "edad": 12,
//   "altura": "50",
//   "peso": "150",
//   "instructor_id": 270,
//   "anio": 2024
// },
// {
//   "id": 68,
//   "nombre": "Mateo",
//   "apellido": "Martinez Resnikoff",
//   "dni": 55571360,
//   "sexo": "2",
//   "categoria": "BLANCO PUNTA AMARILLA",
//   "categoria_id": 2,
//   "instructor": "MARTIN M.",
//   "escuela": null,
//   "edad": 8,
//   "altura": "35",
//   "peso": "135",
//   "instructor_id": 2,
//   "anio": 2024
// },
// {
//   "id": 69,
//   "nombre": "Morena",
//   "apellido": "Martinez Resnikoff",
//   "dni": 56502207,
//   "sexo": "1",
//   "categoria": "BLANCO PUNTA AMARILLA",
//   "categoria_id": 2,
//   "instructor": "MARTIN M.",
//   "escuela": null,
//   "edad": 7,
//   "altura": "35",
//   "peso": "120",
//   "instructor_id": 2,
//   "anio": 2024
// },
// {
//   "id": 70,
//   "nombre": "Leon",
//   "apellido": "Almada",
//   "dni": 52857951,
//   "sexo": "2",
//   "categoria": "PRIMER DAN",
//   "categoria_id": 11,
//   "instructor": "MARTIN M.",
//   "escuela": null,
//   "edad": 11,
//   "altura": "48",
//   "peso": "145",
//   "instructor_id": 2,
//   "anio": 2024
// },
// {
//   "id": 71,
//   "nombre": "Gonzalo",
//   "apellido": "Agis",
//   "dni": 53580052,
//   "sexo": "2",
//   "categoria": "BLANCO PUNTA AMARILLA",
//   "categoria_id": 2,
//   "instructor": "NESTOR A.",
//   "escuela": null,
//   "edad": 10,
//   "altura": "50",
//   "peso": "150",
//   "instructor_id": 1,
//   "anio": 2024
// }

// ]
  
   
 let Certificados =   [];
  alumnos.map((x) => {
    Certificados.push({
      ...x,
      nombreC: x.nombre + " " + x.apellido,

    })
     
  })
  Certificados.sort((a,b)=> a.instructor_id - b.instructor_id)
  
  const titulo = (valor) => {
    let sexo = "Mixto"

    switch (valor) {
      case 1:
        sexo = "Sra."
        break;
      case 2:
        sexo = "Sr."
        break;
      case 3:
        sexo = "Sr/a"
        break;

      default:
        sexo = "Sr/a"
        break;
    }
    return sexo
  }

  const mes_name = (num) => {
    let categoria = "n/c"
    switch (num) {

      case "01":
        categoria = "Enero"
        break;
      case "02":
        categoria = "Febrero"
        break;
      case "03":
        categoria = "Marzo"
        break;
      case "04":
        categoria = "Abril"
        break;
      case "05":
        categoria = "Mayo"
        break;
      case "06":
        categoria = "Junio"
        break;
      case "07":
        categoria = "Julio"
        break;
      case "08":
        categoria = "Agosto"
        break;
      case "09":
        categoria = "Septiembre"
        break;
      case "10":
        categoria = "Octubre"
        break;
      case "11":
        categoria = "Noviembre"
        break;
      case "12":
        categoria = "Diciembre"
        break;

      default:
        break;
    }

    return categoria;
  }

  jsPDF.API.texto = function (texto, ancho, alto) {
    this.setFillColor(255)
    let TN = texto.length;
    if (TN <= 17) { this.setFontSize(20) }
    if (TN >= 18 & TN <= 23) { this.setFontSize(15) }
    if (TN >= 24) { this.setFontSize(10) }
    this.setFont('Helvetica', 'bold')
    this.setTextColor(255)
    this.text(texto, ancho + 37.5, alto + (10), { align: "center" })

  }

  let doc = new jsPDF({
    orientation: "p",
    unit: "mm",
    format: "a4",
    putOnlyUsedFonts: true,
    precision: 2,
  });

  let alto = 5;
  doc.setFontSize(12);
  doc.text(` Nro  `, 10, alto)
  doc.text(` Nombre  `, 20, alto)
  doc.text(` Edad `, 90, alto)
  doc.text(`  Categoria  `, 100, alto)
  doc.text(` Instructor  `, 160, alto)

  alto = 15;

  Certificados.map((x, i) => {
    doc.setFontSize(8);
    doc.setFont('Helvetica')
    doc.text(`  ${i + 1}  `, 10, alto)
    doc.text(`  ${x.nombreC}  `, 20, alto)
    doc.text(`  ${x.edad}  `, 90, alto)
    doc.text(`  ${x.categoria}  `, 100, alto)
    doc.text(`  ${x.instructor}  `, 160, alto)


    alto = alto + 5;

    if (alto + 5 >= 290) {
      doc.addPage()
        alto = 5;
      doc.setFontSize(12);
      doc.text(` Nro  `, 10, alto)
      doc.text(` Nombre  `, 20, alto)
      doc.text(` Edad `, 90, alto)
      doc.text(`  Categoria  `, 100, alto)
      doc.text(` Instructor  `, 160, alto)

      alto = 15;
    }


  })
  doc.output('save', `Inscriptos2024.pdf`);
  // doc.save("yourDoc.pdf");

  listo(false)
  return (
    <div>
    </div>
  );
}

export default PDFInscriptos;